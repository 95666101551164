/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  CloseButton,
  DetailViewDiv,
  DeviceName,
  FinishedButton,
  FinishedDevices,
  FinishedStateDiv,
  ClearAllButton,
} from "../../styles/FlashStationStyle/FinishedStyle"
import { ItalicPre } from "../../styles/FlashStationStyle/InProgressStyle"
import { Title } from "../../styles/FlashStationStyle/ReadyToFlashStyle"
import {
  ClearAll,
  ClearAllDevices,
  ConfirmationMessage,
  FaiVersion,
  FinishedTitle,
} from "../../variables/sharedVariables"
import { getLocalDate } from "../../pages/LocalTimeZone"
import { DeviceState, EState } from "../../utility/types"
import Tooltip from "@mui/material/Tooltip"
import { useEffect, useState } from "react"
import { ErrorNotification } from "../../pages/ErrorDialogBox/ErrorNotification"
import { CreateAxiosConfig } from "../../pages/AxiosConfig"
import { useNotify } from "react-admin"
import {
  flashingStationTransitionsToFrontendTransitions,
  splitDeviceName,
} from "utility/helpingFunctions"
import { useDeviceStateHook } from "utility/getDeviceState"
import { SubContainer } from "styles/CommonStyles"
import {
  ConfirmationDialogBox,
  callClearAllDevicesEndpoint,
} from "Components/ConfirmationDialogBox"
import { DetailView } from "Components/DetailView/DetailViewWindow"

interface FinishedProps {
  deviceData: DeviceState[]
}
export const Finished = ({ deviceData }: FinishedProps) => {
  const notify = useNotify()
  const axiosAlphasenseFactory = CreateAxiosConfig("/api/v1")
  const [showDetails, setShowDetails] = useState<string | null>(null)
  const [deletedDevices, setDeletedDevices] = useState<number[]>([])
  const { deviceMonitoringStates, updateDeviceMonitoringState } = useDeviceStateHook()
  const [openDialogBox, setOpenDialogBox] = useState(false)

  const showDetailsOnClick = (portName: string) =>
    setShowDetails((prev) => (prev === portName ? null : portName))
  const allCompletedDevices = deviceData.filter((device: DeviceState) =>
    [EState.COMPLETED, EState.FAILED, EState.FAILED_ACKNOWLEDGED].includes(device.state),
  )
  allCompletedDevices.sort((deviceA, deviceB) =>
    Date.parse(deviceA.transitions[deviceA.transitions.length - 1].timestamp) >
    Date.parse(deviceB.transitions[deviceB.transitions.length - 1].timestamp)
      ? 1
      : -1,
  )
  const uniqueDevices = [
    ...new Map(allCompletedDevices.map((item) => [item.device_name, item])).values(),
  ]
  const filteredDevices = uniqueDevices.filter(
    (item: DeviceState) => !deletedDevices.includes(item.id),
  )

  const postAcknowledgement = (device_id: number) => {
    axiosAlphasenseFactory
      .post(`/devices/acknowledge/${device_id}`, { body: device_id })
      .catch(() => notify("failed to acknowledge"))
  }

  const hideButton = (id: number) => {
    axiosAlphasenseFactory.delete(`/devices/${id}`).catch(() => notify("Failed to delete device"))
    setDeletedDevices((prevList) => {
      prevList.push(id)
      return prevList
    })
  }

  useEffect(() => {
    updateDeviceMonitoringState(filteredDevices, FaiVersion)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredDevices.map((device) => device.device_name || "").join(",")])

  const isDevicesEmpty = filteredDevices.length === 0

  return (
    <SubContainer>
      <Title>{FinishedTitle}</Title>
      <FinishedStateDiv>
        <ClearAllButton
          onClick={() => setOpenDialogBox(true)}
          disabled={isDevicesEmpty}
          style={{
            background: filteredDevices.length === 0 ? "rgba(120, 120, 120, 0.6)" : "#e44411",
            cursor: isDevicesEmpty ? "not-allowed" : "pointer",
          }}
        >
          {ClearAll}
        </ClearAllButton>
        <FinishedDevices>
          {filteredDevices.map((data) => {
            const device_name = data.device_name || "unknown"
            const isFailed = (EState.FAILED, EState.FAILED_ACKNOWLEDGED).includes(data.state)
            const flashTransitions = flashingStationTransitionsToFrontendTransitions(
              data.transitions,
            )
            return (
              <div key={device_name}>
                <Tooltip
                  title={`${getLocalDate(data.transitions[data.transitions.length - 1].timestamp)}`}
                >
                  <FinishedButton
                    style={{ background: isFailed ? "#AF1A1A" : "green", cursor: "pointer" }}
                    onClick={() => showDetailsOnClick(data.port_name!)}
                  >
                    <CloseButton onClick={() => hideButton(data.id!)}>X</CloseButton>
                    <DeviceName>
                      <ItalicPre>{data.port_name}</ItalicPre>
                      {splitDeviceName(device_name)}
                      <span>
                        {deviceMonitoringStates.get(FaiVersion)?.get(device_name)?.message || ""}
                      </span>
                    </DeviceName>
                  </FinishedButton>
                </Tooltip>
                {showDetails === data.port_name && (
                  <DetailViewDiv>
                    <DetailView transitions={flashTransitions} ifBootstrapPage={false} />
                  </DetailViewDiv>
                )}
                {data.state === EState.FAILED ? (
                  <ErrorNotification
                    open={true}
                    onClose={() => postAcknowledgement(data.id!)}
                    onAcknowledge={() => postAcknowledgement(data.id!)}
                    portName={data.port_name!}
                    deviceName={device_name}
                  />
                ) : (
                  ""
                )}
              </div>
            )
          })}
        </FinishedDevices>
      </FinishedStateDiv>
      {openDialogBox && (
        <ConfirmationDialogBox
          title={ClearAllDevices}
          message={ConfirmationMessage}
          buttonText={ClearAll}
          onConfirm={() => {
            callClearAllDevicesEndpoint("/devices", notify)
            setDeletedDevices!(
              filteredDevices.map((item: any) => item.device_name || item.hostname),
            )
          }}
          {...{ openDialogBox, setOpenDialogBox }}
        />
      )}
    </SubContainer>
  )
}
