import {
  RadioButtonUnchecked,
  CheckCircleOutline,
  ArrowDropDown,
  ArrowDropUp,
} from "@mui/icons-material"
import { PaddingLeftDiv, RowAlignCenterDiv } from "styles/ReportsStyle"
import { FailedCheckResults } from "types/calibrationReportTypes"
import { translateReport } from "utility/helpingFunctions"
import { calibrationReportTranslations } from "utility/translations"
import { Check } from "./Check"
import { useState } from "react"

interface SensorCheckProps {
  sensor: string
  failed_check_results?: FailedCheckResults
}

export const SensorCheck = ({ sensor, failed_check_results }: SensorCheckProps) => {
  const [showSensorCheck, setShowSensorCheck] = useState(false)

  return (
    <div style={sensor.startsWith("cam") ? { paddingLeft: "40px" } : {}}>
      <RowAlignCenterDiv
        onClick={() => setShowSensorCheck((prevValue) => !prevValue)}
        style={failed_check_results?.[sensor] ? { cursor: "pointer" } : {}}
      >
        {failed_check_results?.[sensor] ? (
          <RadioButtonUnchecked style={{ color: "red", marginRight: "10px" }} />
        ) : (
          <CheckCircleOutline style={{ color: "green", marginRight: "10px" }} />
        )}
        <span>{translateReport(sensor, calibrationReportTranslations)}</span>
        {failed_check_results?.[sensor] &&
          (showSensorCheck ? (
            <ArrowDropUp style={{ color: "lightgray", fontSize: "30px" }} />
          ) : (
            <ArrowDropDown style={{ color: "lightgray", fontSize: "30px" }} />
          ))}
      </RowAlignCenterDiv>

      {showSensorCheck && (
        <PaddingLeftDiv>
          {failed_check_results?.[sensor] &&
            Object.entries(failed_check_results[sensor]).map(([check]) => (
              <Check
                key={`check-${check}`}
                check={check}
                checkDetails={failed_check_results[sensor]}
              />
            ))}
        </PaddingLeftDiv>
      )}
    </div>
  )
}
