import logo from "../../assets/images/logo.svg"
import { Divider, Select } from "@mui/material"
import {
  Bootstrapping,
  ChangePassword,
  FlashingStationTitle,
  Logged,
  Logout,
  MainTitle,
  VersionInfo,
} from "../../variables/sharedVariables"
import {
  NavBarContainer,
  Logo,
  LogoLink,
  BoxDiv,
  StyledBox,
  Title,
  NavLinks,
  StyledWrapper,
  LogoutBox,
  StyledSpan,
  LoggedDropDown,
  MenuItems,
  NavItemLink,
  NavLinkRight,
  FlashingStationLink,
} from "../../styles/NavBarStyle"
import { useRedirect } from "react-admin"
import { logOut } from "utility/user_authentication"
import { useState } from "react"
import { SoftwareVersionsDialog } from "./CurrentVersionsPopUp"
import { MenuProps } from "Components/FlashingStation/ReadyToFlash"

export const NavBar = () => {
  const redirect = useRedirect()
  const [showVersionInfoOpen, setShowVersionInfoOpen] = useState(false)

  const appendIfActive = (navLink: string, isActive: boolean) => {
    return [navLink, isActive ? "active" : null].filter(Boolean).join(" ")
  }

  const userName = localStorage.getItem("username") as string
  const canViewFlashingStationData = localStorage.getItem("can_view_flashing_station")
  const parsedCanViewFlashingStationData =
    canViewFlashingStationData && JSON.parse(canViewFlashingStationData)

  return (
    <NavBarContainer>
      <StyledBox>
        <LogoLink>
          <BoxDiv>
            <Logo src={logo} />
            <Title>{MainTitle}</Title>
            <Divider orientation="vertical" flexItem />
          </BoxDiv>
        </LogoLink>
        <NavLinks>
          {parsedCanViewFlashingStationData ? (
            <FlashingStationLink
              to="/flashingstation"
              className={({ isActive }) => appendIfActive("nav-link", isActive)}
            >
              {FlashingStationTitle}
            </FlashingStationLink>
          ) : (
            <></>
          )}
          <NavItemLink
            to="/bootstrapping"
            className={({ isActive }) => appendIfActive("nav-link", isActive)}
          >
            {Bootstrapping}
          </NavItemLink>
        </NavLinks>
        <NavLinkRight>
          <StyledWrapper>
            <LogoutBox>
              <Divider orientation="vertical" flexItem />
              <StyledSpan>{Logged}</StyledSpan>
              <LoggedDropDown variant="standard">
                <Select
                  value={userName}
                  displayEmpty
                  disableUnderline
                  MenuProps={MenuProps}
                  sx={{
                    boxShadow: "none",
                    padding: "10px",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    "& .MuiSelect-select:focus": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <MenuItems value={userName}>{userName}</MenuItems>
                  <MenuItems value="Change Password" onClick={() => redirect("/change_password")}>
                    {ChangePassword}
                  </MenuItems>
                  <MenuItems value="Show Software" onClick={() => setShowVersionInfoOpen(true)}>
                    {VersionInfo}
                  </MenuItems>
                  <MenuItems value="Logout" onClick={() => logOut(redirect)}>
                    {Logout}
                  </MenuItems>
                </Select>
              </LoggedDropDown>
            </LogoutBox>
          </StyledWrapper>
        </NavLinkRight>
      </StyledBox>
      {showVersionInfoOpen && (
        <SoftwareVersionsDialog
          open={showVersionInfoOpen}
          onClick={() => setShowVersionInfoOpen(false)}
        />
      )}
    </NavBarContainer>
  )
}
