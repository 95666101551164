import { useState } from "react"
import useWebSocket from "react-use-websocket"
import { decode } from "@msgpack/msgpack"
import { Buffer } from "buffer"
import { EOdometry } from "utility/types"

export interface WebSocketState {
  deviceHeartBeats: Map<string, number>
  images: Map<string, Map<number, string>>
  odometry: Map<string, EOdometry>
}

interface ImageMessage {
  device_name: string
  camera_idx: number
  image: number[]
}

const isOdometryKey = (key: string): key is keyof typeof EOdometry => {
  return key in EOdometry
}

export const WebSocketRequest = (): WebSocketState => {
  const [images, setImages] = useState<Map<string, Map<number, string>>>(new Map())
  const [deviceHeartBeats, setDeviceHeartBeats] = useState<Map<string, number>>(new Map())
  const [odometry, setOdometry] = useState<Map<string, EOdometry>>(new Map())
  const socketUrl = `/api/v1/frontend_stream`
  const token = localStorage.getItem("token")

  const { sendMessage } = useWebSocket(socketUrl, {
    onOpen: () => {
      console.log("connection opened")
      token && sendMessage(token)
    },
    onMessage: async (message) => {
      if (message.data instanceof Blob) {
        //TODO if we ever receive something else in binary we have to handle that here
        const decodedData = decode(await message.data.arrayBuffer()) as ImageMessage
        setImages((prevData) => {
          const old_device_data =
            prevData.get(decodedData.device_name) || (new Map() as Map<number, string>)
          const base64String = Buffer.from(decodedData.image).toString("base64")
          const dataURL = `data:image/png;base64,${base64String}`
          prevData.set(
            decodedData.device_name,
            old_device_data.set(decodedData.camera_idx, dataURL),
          )
          return prevData
        })
      } else {
        const data = JSON.parse(message.data)

        switch (data.type) {
          case "device_ping":
            setDeviceHeartBeats((prevData) => prevData.set(data.device_name, Date.now()))
            break
          case "state": {
            const motion_type = data.data.motion_type as string
            if (isOdometryKey(motion_type)) {
              setOdometry((prevData) => prevData.set(data.device_name, EOdometry[motion_type]))
            }
            break
          }
          default:
            break
        }
      }
    },
    onClose: (event) => {
      console.log("connection closed", event)
    },
    shouldReconnect: () => true,
    onError: (event) => console.log(event),
    reconnectInterval: 1000,
  })

  return { deviceHeartBeats, images, odometry }
}
