import { parseTwoDecimals } from "pages/CalibrationReport/CheckValues"
import { IoTriangle } from "react-icons/io5"
import {
  CursorPointerDiv,
  CustomSliderDiv,
  GraySubinterval,
  GreenBoundLine,
  GreenBoundText,
  GreenSubinterval,
  LeftGraySubinterval,
  MiddleGreenSubinterval,
  RedCursorText,
  RightGraySubinterval,
} from "styles/CustomSliderStyle/CustomSliderStyle"

interface CustomSliderProps {
  value: number
  min?: number | null
  max?: number | null
}

interface RedCursorPointerProps {
  cursorPosition: number
  value: number
}

const RedCursorPointer = ({ cursorPosition, value }: RedCursorPointerProps) => (
  <CursorPointerDiv style={{ paddingLeft: `${cursorPosition}%` }}>
    <IoTriangle color="red" />
    <RedCursorText>{parseTwoDecimals(value)}</RedCursorText>
  </CursorPointerDiv>
)

const CustomSliderInterval = ({ value, min, max }: CustomSliderProps) => {
  if (!(max && min)) return <></>

  // Used linear interpolation to determine the cursor position
  let cursorPosition
  if (value < min) {
    cursorPosition = Math.min((37.5 * (value - min / 3)) / min, 21)
  } else {
    cursorPosition = Math.min((12.5 * (value + 5 * max)) / max, 90)
  }

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <GreenBoundText style={{ paddingLeft: "23%" }}>{parseTwoDecimals(min)}</GreenBoundText>
        <GreenBoundText style={{ paddingLeft: "40%" }}>{parseTwoDecimals(max)}</GreenBoundText>
      </div>
      <div style={{ position: "relative" }}>
        <GreenBoundLine style={{ left: "25%" }} />
        <GreenBoundLine style={{ left: "75%" }} />
        <CustomSliderDiv>
          <LeftGraySubinterval />
          <MiddleGreenSubinterval />
          <RightGraySubinterval />
        </CustomSliderDiv>
      </div>
      <RedCursorPointer {...{ cursorPosition, value }} />
    </>
  )
}

const CustomLimit = ({ value, min, max }: CustomSliderProps) => {
  let cursorPosition: number
  let paddingLeft: string
  let left: string

  if (min) {
    cursorPosition = Math.min((112.5 * (value - min / 3)) / min, 70)
    paddingLeft = "72%"
    left = "75%"
  } else if (max) {
    cursorPosition = Math.min((75 * (value - max)) / (2 * max) + 25, 90)
    paddingLeft = "22%"
    left = "25%"
  } else return <></>

  return (
    <>
      <GreenBoundText style={{ paddingLeft }}>
        {min ? parseTwoDecimals(min) : parseTwoDecimals(max as number)}
      </GreenBoundText>
      <div style={{ position: "relative" }}>
        <GreenBoundLine style={{ left }} />
        <CustomSliderDiv>
          {min ? (
            <>
              <GraySubinterval style={{ borderRadius: "6px 0 0 6px" }} />
              <GreenSubinterval style={{ borderRadius: "0 6px 6px 0" }} />
            </>
          ) : (
            <>
              <GreenSubinterval style={{ borderRadius: "6px 0 0 6px" }} />
              <GraySubinterval style={{ borderRadius: "0 6px 6px 0" }} />
            </>
          )}
        </CustomSliderDiv>
      </div>
      <RedCursorPointer {...{ cursorPosition, value }} />
    </>
  )
}

export const CustomSlider = ({ value, min, max }: CustomSliderProps) => {
  return (
    <div style={{ width: "300px" }}>
      {min && max ? (
        <CustomSliderInterval {...{ value, min, max }} />
      ) : min ? (
        <CustomLimit {...{ value, min }} />
      ) : max ? (
        <CustomLimit {...{ value, max }} />
      ) : (
        <></>
      )}
    </div>
  )
}
