import { TableCell } from "@mui/material"
import styled, { css } from "styled-components"
import { Flex } from "./ProgressReportStyles"

export const MediaQueryWidth = css`
  @media (max-width: 768px) {
    width: 150px;
  }
`
export const TableCellForDate = styled(TableCell)`
  width: 200px;
  ${MediaQueryWidth}
`
export const TableCellForState = styled(TableCell)`
  width: 180px;
  ${MediaQueryWidth}
`
export const HorizontalLineForSoftwareVersion = styled.hr`
  width: 95%;
  opacity: 0.3;
`
export const SoftwareVersion = styled.p`
  text-align: center;
  font-style: italic;
  color: rgba(0, 0, 0, 0.87);
`
export const FlexColumn = css`
  display: flex;
  flex-direction: column;
`
export const ReportLink = styled.a`
  color: blue;
  cursor: pointer;
  text-decoration: none;
`
export const DivWithStatus = styled.div`
  color: red;
  line-height: 25px;
`
export const DivForButtons = styled.div`
  ${FlexColumn}
  margin-top: 30px;
`
export const CustomButtonForProcessState = styled.button`
  height: 35px;
  color: white;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-weight: 500;
`
export const ButtonsForProcessState = styled(CustomButtonForProcessState)`
  width: 300px;
  @media (max-width: 768px) {
    width: 250px;
  }
`
export const CommonMarginBottom = css`
  margin-bottom: 10px;
  cursor: pointer;
`
export const ProceedButton = styled(ButtonsForProcessState)`
  ${CommonMarginBottom}
`
export const RepeatButton = styled(ButtonsForProcessState)`
  ${CommonMarginBottom}
  background:  #e44411;
  margin-bottom: 10px;
  &:disabled {
    background: rgba(120, 120, 120, 0.6);
    cursor: not-allowed;
  }
`
export const ReportShareButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  width: 300px;
`
export const ReportButton = styled(CustomButtonForProcessState)`
  ${CommonMarginBottom}
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #e44411;
  background: #fadad2;
  &disabled {
    background: rgba(120, 120, 120, 0.6);
    cursor: not-allowed;
  }
`
export const SubmitShareWith7SButton = styled(CustomButtonForProcessState)`
  ${CommonMarginBottom}
  width: 120px;
  background: #e44411;
`
export const FlexRow = styled.div`
  ${Flex}
  margin-left: 80px;
  @media (max-width: 768px) {
    margin-left: 30px;
  }
`
export const LeftRowWidth = styled.div`
  width: 25%;
  text-align: left;
  @media (max-width: 768px) {
    width: 35%;
  }
  @media (max-width: 412px) {
    width: 50%;
  }
`
export const RightRowWidth = styled.div`
  width: 75%;
  text-align: left;
  @media (max-width: 768px) {
    width: 65%;
  }
  @media (max-width: 412px) {
    width: 50%;
  }
`
export const CameraAndButtonComponent = styled(FlexRow)`
  flex-direction: row;
  @media (max-width: 412px) {
    flex-direction: row;
  }
`

export const PinButton = styled.button`
  width: 100%;
  color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #e44411;
  height: 35px;
  padding-top: 6px;
`

export const CameraButton = styled(PinButton)`
  margin-right: 10px;
`
