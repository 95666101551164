import { makeStyles } from "@mui/styles"
import { Dialog, IconButton, Paper } from "@mui/material"
import { ConfigurationReportPage } from "./ConfigurationReport"
import { CalibrationReportPage, ReportProps } from "../CalibrationReport/CalibrationReport"
import { QualityAssuranceReportPage } from "./QualityAssuranceReport"
import CloseIcon from "assets/icons/CloseIcon.svg"
import { ColumnWithoutJustified } from "styles/ProgressReportStyles"
import { EState } from "utility/types"

export interface DialogProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  inProgressStates: string
  reportDetails?: ReportProps
}

const customStyles: any = makeStyles(() => ({
  dialogContainer: {
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    maxHeight: "100%",
    maxWidth: "100%",
    background: "white",
  },
}))

export const ReportPage = ({ open, inProgressStates, setOpen, reportDetails }: DialogProps) => {
  const classes = customStyles()
  const clickClose = () => {
    setOpen(false)
  }

  return (
    <Dialog open={open}>
      <Paper className={classes.dialogContainer}>
        <ColumnWithoutJustified>
          <IconButton aria-label="close" onClick={clickClose} style={{ alignSelf: "self-end" }}>
            <img src={CloseIcon} alt="Close" />
          </IconButton>
          <div>
            {inProgressStates === EState.CONFIGURATION
              ? reportDetails && <ConfigurationReportPage {...{ ...reportDetails }} />
              : inProgressStates === EState.CALIBRATION
                ? reportDetails && <CalibrationReportPage {...{ ...reportDetails }} />
                : reportDetails && <QualityAssuranceReportPage {...{ ...reportDetails }} />}
          </div>
        </ColumnWithoutJustified>
      </Paper>
    </Dialog>
  )
}
