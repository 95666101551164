import { useEffect, useState } from "react"
import EvaluationKit from "../assets/icons/EvaluationKitIcon.png"
import { FaRobot } from "react-icons/fa"
import { useDeviceStateHook } from "./getDeviceState"
import {
  DeviceStatus,
  DeviceWithFrontendTransitions,
  DeviceWithTransitions,
  EState,
  SessionType,
  isFailureStatus,
} from "./types"
import { Offline, SoftwareVersion, StartBootstrappingMessage } from "variables/sharedVariables"
import {
  getSessionForDevice,
  mapBackendTransitionsToFrontendTransitions,
  isReleaseVersionOld,
  slicedTransitions,
  getDeviceStatus,
} from "./helpingFunctions"
import { DetailView } from "Components/DetailView/DetailViewWindow"
import { DetailedViewOfTheState } from "Components/DetailView/DetailedViewOfTheState"
import { CameraImagesView } from "Components/DetailView/CameraImagesView"
import { ProgressBar } from "Components/ProgressBar/ProgressBar"
import { DeviceNameDiv, IconTextDiv, ProgressBarDiv, ToggleButtonsDiv } from "styles/CommonStyles"
import {
  ActionRequiredPopUp,
  HorizontalLine,
  InProgressDiv,
  SpanBold,
  StatusDiv,
} from "styles/FlashStationStyle/InProgressStyle"
import { BlinkingButton, DeviceNameButton } from "styles/FlashStationStyle/ReadyToFlashStyle"
import { deviceName } from "utility/helpingFunctions"
import { ActionRequired, InActiveDevice } from "variables/sharedVariables"
import { ToggleDetailView } from "Components/FlashingStation/ToggleDetailView"
import { Tooltip } from "@mui/material"
import { CalibrationSubStates } from "utility/translations"
import { Filter } from "Components/Bootstrapping/InProgressSession"
import { getPinnedDevices, setUserWithPinnedDevices } from "./localStorage"
import { completedAssuranceStateRegex } from "./regex"
import { RobotIdentityDialog } from "Components/RobotIdentityDialog/RobotIdentityDialog"

const steps = [
  { label: EState.CONFIGURATION },
  { label: EState.CALIBRATION },
  { label: EState.ACCEPTANCE },
]

export const useDevicesWithTransitions = (
  bootstrappingDevices: DeviceWithTransitions[],
  ifBootstrapPage: boolean,
  session: SessionType,
  activeFilters: Map<string, Filter>,
  pinnedIds: string[],
  setPinnedIds: React.Dispatch<React.SetStateAction<string[]>>,
  pinnedDevicesUpdateTime: number,
  setPinnedDevicesUpdateTime: React.Dispatch<React.SetStateAction<number>>,
  onChangeRobotIdentity?: () => void,
) => {
  const { deviceMonitoringStates, updateDeviceMonitoringState } = useDeviceStateHook()
  const [toggleDetailsButtonId, setToggleDetailsButtonId] = useState("")
  const [toggleCameraImagesId, setToggleCameraImagesId] = useState("")
  const [toggleIdentityRobotDialog, setToggleIdentityRobotDialog] = useState("")

  useEffect(() => {
    setPinnedIds(getPinnedDevices())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinnedDevicesUpdateTime])

  const pinAndUnPinDevice = (deviceId: string) => {
    const prevPinnedDeviceIds = getPinnedDevices()
    let updatedPinnedDeviceIds
    if (prevPinnedDeviceIds.includes(deviceId)) {
      updatedPinnedDeviceIds = prevPinnedDeviceIds.filter((pinnedId) => pinnedId !== deviceId)
    } else {
      updatedPinnedDeviceIds = [...prevPinnedDeviceIds, deviceId]
    }
    setPinnedDevicesUpdateTime(new Date().getTime())
    setUserWithPinnedDevices(updatedPinnedDeviceIds)

    if (toggleDetailsButtonId === deviceId) {
      setToggleDetailsButtonId("")
    }
    if (toggleCameraImagesId === deviceId) {
      setToggleCameraImagesId("")
    }
  }

  const activeDevices = bootstrappingDevices
    .filter(
      (item: DeviceWithTransitions) =>
        item.is_bootstrapping_active || pinnedIds.includes(item.hostname),
    )
    .sort((a, b) => a.hostname.localeCompare(b.hostname))

  //TODO we could reduce this by only asking for the ones that are in our session
  useEffect(() => {
    updateDeviceMonitoringState(activeDevices, SoftwareVersion)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDevices.map((device) => device.hostname || "").join(",")])

  useEffect(() => {
    updateDeviceMonitoringState(activeDevices, "calibration_server_error")
    const interval = setInterval(() => {
      updateDeviceMonitoringState(activeDevices, "calibration_server_error")
    }, 2000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDevices.map((device) => device.hostname || "").join(",")])
  let devicesToDisplay = activeDevices
  activeFilters.forEach((element) => {
    devicesToDisplay = element(devicesToDisplay)
  })
  const devicesWithFrontendTransitions: DeviceWithFrontendTransitions[] = devicesToDisplay
    .map((data: DeviceWithTransitions) => {
      const filteredTransitions = slicedTransitions(data.transitions)
      const filteredCalibrationTransitions = slicedTransitions(data.calibrationTransitions)
      const frontendTransitions = mapBackendTransitionsToFrontendTransitions(
        data.is_bootstrapping_active,
        filteredTransitions,
        filteredCalibrationTransitions,
      )
      const lastTransition = frontendTransitions[frontendTransitions.length - 1]
      const softwareVersion = deviceMonitoringStates
        .get(SoftwareVersion)
        ?.get(data.hostname)?.message
      const isOldReleaseVersion = isReleaseVersionOld(softwareVersion)
      const status = getDeviceStatus(data, deviceMonitoringStates, lastTransition?.state)
      const isAborted = lastTransition && lastTransition.substate === "ReadyForBootstrapping"
      const isPinned = pinnedIds.includes(data.hostname)

      return {
        hostname: data.hostname,
        isDeviceActive: data.isDeviceActive || isOldReleaseVersion,
        is_bootstrapping_active: data.is_bootstrapping_active || isPinned,
        session: getSessionForDevice(status, isAborted, lastTransition?.substate, isPinned),
        status,
        transitions: frontendTransitions,
        isAborted,
        isOldReleaseVersion,
        bootstrapStateInfo: data.bootstrapStateInfo,
        robot_model_name: data.robot_model_name,
        robot_serial_no: data.robot_serial_no,
      }
    })
    .filter((data: DeviceWithFrontendTransitions) => {
      return data.session === session
    })
  const showInProgressDevices = devicesWithFrontendTransitions.map(
    (deviceWithFrontendTransitions: DeviceWithFrontendTransitions, index) => {
      const lastTransition =
        deviceWithFrontendTransitions.transitions[
          deviceWithFrontendTransitions.transitions.length - 1
        ]

      const isStateFailed = isFailureStatus(deviceWithFrontendTransitions.status)
      const inProgressState = lastTransition ? lastTransition.state : "Unknown"
      const softwareVersion = deviceMonitoringStates
        .get(SoftwareVersion)
        ?.get(deviceWithFrontendTransitions.hostname)

      const lastSubState = lastTransition && lastTransition.substate
      const isPinned = pinnedIds.includes(deviceWithFrontendTransitions.hostname)
      const NameButton = deviceWithFrontendTransitions.isDeviceActive
        ? BlinkingButton
        : DeviceNameButton
      const isCalibrationIsWaitingForArenaLocalization =
        lastSubState && lastSubState === CalibrationSubStates.WaitingForArenaLocalization
      const lastSubStateFromTransition = lastTransition ? lastTransition.substate : null
      const assuranceStateCompleted =
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        completedAssuranceStateRegex.test(lastSubStateFromTransition!) &&
        !deviceWithFrontendTransitions.is_bootstrapping_active

      const showCameraImages = toggleCameraImagesId === deviceWithFrontendTransitions.hostname
      const showDetailedView = toggleDetailsButtonId === deviceWithFrontendTransitions.hostname

      return (
        <div key={`device-${deviceWithFrontendTransitions.hostname}`}>
          {(inProgressState !== EState.COMPLETED && lastSubStateFromTransition !== null) ||
          isPinned ? (
            <>
              <InProgressDiv style={{ marginTop: ifBootstrapPage ? "50px" : "" }}>
                <DeviceNameDiv>
                  <Tooltip
                    title={!deviceWithFrontendTransitions.isDeviceActive ? InActiveDevice : ""}
                  >
                    <NameButton
                      style={{
                        background:
                          isStateFailed && deviceWithFrontendTransitions.isDeviceActive
                            ? "#AF1A1A"
                            : !deviceWithFrontendTransitions.isDeviceActive
                              ? "rgba(120, 120, 120, 0.6)"
                              : "#2f4b8a",
                        height:
                          deviceWithFrontendTransitions.hostname.length > 10 ? "auto" : "55px",
                        marginBottom: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setToggleIdentityRobotDialog(deviceWithFrontendTransitions.hostname)
                      }
                    >
                      {deviceName(deviceWithFrontendTransitions)}
                      {!deviceWithFrontendTransitions.isDeviceActive && (
                        <pre style={{ marginTop: "3px" }}>{Offline}</pre>
                      )}
                    </NameButton>
                  </Tooltip>
                  {deviceWithFrontendTransitions.robot_serial_no && (
                    <IconTextDiv>
                      <img
                        height={24}
                        style={{ paddingRight: "3px" }}
                        src={EvaluationKit}
                        alt="EvaluationKitIcon"
                      />
                      <span>{deviceWithFrontendTransitions.hostname}</span>
                    </IconTextDiv>
                  )}
                  {deviceWithFrontendTransitions.robot_model_name && (
                    <IconTextDiv>
                      <FaRobot size={24} />
                      <span>{deviceWithFrontendTransitions.robot_model_name}</span>
                    </IconTextDiv>
                  )}
                </DeviceNameDiv>
                <ProgressBarDiv>
                  <ProgressBar
                    state={inProgressState}
                    isDeviceActive={deviceWithFrontendTransitions.isDeviceActive}
                    {...{
                      steps,
                      isStateFailed,
                      ifBootstrapPage,
                      isPinned,
                    }}
                  />
                </ProgressBarDiv>
                <ToggleButtonsDiv style={{ paddingLeft: "15px" }}>
                  <ToggleDetailView
                    id={deviceWithFrontendTransitions.hostname}
                    isDeviceActive={deviceWithFrontendTransitions.isDeviceActive}
                    isDeviceAborted={deviceWithFrontendTransitions.isAborted}
                    isOldReleaseVersion={deviceWithFrontendTransitions.isOldReleaseVersion}
                    {...{
                      toggleDetailsButtonId,
                      setToggleDetailsButtonId,
                      toggleCameraImagesId,
                      setToggleCameraImagesId,
                      ifBootstrapPage,
                      calibrationStatus: null,
                      calibrationResultPath: null,
                      softwareVersion,
                      pinnedIds,
                      assuranceStateCompleted,
                    }}
                    pinAndUnPinDevice={() =>
                      pinAndUnPinDevice(deviceWithFrontendTransitions.hostname)
                    }
                  />
                </ToggleButtonsDiv>
              </InProgressDiv>
              <StatusDiv>
                <>
                  {isCalibrationIsWaitingForArenaLocalization ||
                    (deviceWithFrontendTransitions.isAborted &&
                      deviceWithFrontendTransitions.isDeviceActive && (
                        <ActionRequiredPopUp>
                          <SpanBold>{ActionRequired}</SpanBold>
                          {isCalibrationIsWaitingForArenaLocalization
                            ? CalibrationSubStates.WaitingForArenaLocalization
                            : StartBootstrappingMessage}
                        </ActionRequiredPopUp>
                      ))}
                </>
                {deviceWithFrontendTransitions.status !== DeviceStatus.Ok &&
                !deviceWithFrontendTransitions.isOldReleaseVersion ? (
                  <DetailedViewOfTheState
                    deviceName={deviceWithFrontendTransitions.hostname}
                    inProgressStates={inProgressState}
                    isDeviceActive={deviceWithFrontendTransitions.isDeviceActive}
                    deviceStatus={deviceWithFrontendTransitions.status}
                    bootstrapStateInfo={deviceWithFrontendTransitions.bootstrapStateInfo}
                  />
                ) : (
                  <></>
                )}
                {showCameraImages && <CameraImagesView deviceName={toggleCameraImagesId} />}
                {showDetailedView && (
                  <DetailView
                    transitions={deviceWithFrontendTransitions.transitions}
                    softwareVersion={softwareVersion?.message}
                    {...{ ifBootstrapPage }}
                  />
                )}
              </StatusDiv>
              {index !== devicesWithFrontendTransitions.length - 1 && <HorizontalLine />}
              {toggleIdentityRobotDialog && (
                <RobotIdentityDialog
                  {...{
                    toggleIdentityRobotDialog,
                    setToggleIdentityRobotDialog,
                    deviceWithFrontendTransitions,
                  }}
                  callbackOnSubmit={() => onChangeRobotIdentity && onChangeRobotIdentity()}
                />
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      )
    },
  )
  return <>{showInProgressDevices}</>
}
