import { Checkbox, FormControl, Grid, MenuItem, SelectChangeEvent } from "@mui/material"
import { useNotify } from "react-admin"
import { ItalicPre, P, ReportSelect } from "../../styles/FlashStationStyle/InProgressStyle"
import {
  FlashButton,
  Title,
  ReadyToFlashButton,
  FlashButtonGrid,
  ReadyToFlashButtonGrid,
} from "../../styles/FlashStationStyle/ReadyToFlashStyle"
import {
  AuthFailedError,
  ReadyForFlash,
  SoftwareReleaseVersion,
  StartFlashing,
} from "../../variables/sharedVariables"
import { CreateAxiosConfig } from "../../pages/AxiosConfig"
import { DeviceState, EState } from "../../utility/types"
import { SubContainer } from "styles/CommonStyles"
import { useState } from "react"
import { AxiosResponse } from "axios"

export const MenuProps = {
  sx: {
    "&& .Mui-selected": {
      backgroundColor: "transparent !important",
      "&:hover": {
        backgroundColor: "rgba(228, 68, 17, 0.1) !important",
      },
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "rgba(228, 68, 17, 0.1)",
    },
  },
}

interface DeviceDataProps {
  deviceData: DeviceState[]
}
export const ReadyToFlash = ({ deviceData }: DeviceDataProps) => {
  const notify = useNotify()
  const axiosAlphasenseFactory = CreateAxiosConfig("/api/v1")
  const versions = [
    "release/2.9",
    "release/3.6",
    "release/3.8",
    "release/3.10",
    "release/3.11",
    "release/3.12",
  ]
  const [selectedOption, setSelectedOption] = useState(versions[1])
  const [isDeveloperFaiChecked, setIsDeveloperFaiChecked] = useState(false)
  const canRunDeveloperFai = localStorage.getItem("can_run_developer_fai") === "true"

  const readyToFlashDevices = deviceData
    .filter((device: DeviceState) =>
      [EState.READY_FOR_FLASHING, EState.UNKNOWN_PORT].includes(device.state),
    )
    .map((data: DeviceState) => {
      return (
        <ReadyToFlashButton
          id={data.port_name}
          style={{
            background: data.state === EState.UNKNOWN_PORT ? "rgba(120, 120, 120, 0.6)" : "green",
          }}
        >
          <ItalicPre>{data.port_name}</ItalicPre>
        </ReadyToFlashButton>
      )
    })

  const flashingButtonActive =
    readyToFlashDevices.length >= 1 &&
    deviceData.filter((device: DeviceState) => device.state === EState.UNKNOWN_PORT).length === 0

  const startFlashing = (version: string) => {
    const splitVersion = version.split("/")
    const versionNumbers = splitVersion[1].split(".")
    const release_version_major = parseInt(versionNumbers[0])
    const release_version_minor = parseInt(versionNumbers[1])
    const device_type = "WETROK_HLC_GEN1"
    const machine_classes = [device_type, "WETROK_MARVIN_21", "AUTONOMY"]
    const data = {
      device_type,
      machine_classes,
      release: {
        release_name: splitVersion[0],
        release_version_major,
        release_version_minor,
      },
      developer_fai: isDeveloperFaiChecked,
    }
    axiosAlphasenseFactory
      .post("/flash_devices", data)
      .then((response: AxiosResponse) => console.log(response))
      .catch(() => notify(AuthFailedError, { type: "error" }))
  }

  return (
    <SubContainer>
      <Grid alignItems="flex-start" container spacing={1}>
        <Grid container direction="column" item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Title>{ReadyForFlash}</Title>
          </Grid>
          <FlashButtonGrid item xs={4}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <P>{SoftwareReleaseVersion}</P>
              <FormControl variant="standard">
                <ReportSelect
                  disableUnderline
                  MenuProps={MenuProps}
                  sx={{
                    boxShadow: "none",
                    paddingLeft: "15px",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    "& .MuiSelect-select:focus": {
                      backgroundColor: "transparent",
                    },
                  }}
                  value={selectedOption}
                  onChange={(event: SelectChangeEvent<unknown>) =>
                    setSelectedOption(event.target.value as string)
                  }
                >
                  {versions.map((version) => (
                    <MenuItem key={version} value={version}>
                      {version}
                    </MenuItem>
                  ))}
                </ReportSelect>
              </FormControl>

              {canRunDeveloperFai && (
                <p>
                  <Checkbox
                    style={{ color: "#e44411", marginLeft: "-10px" }}
                    onChange={() => setIsDeveloperFaiChecked(!isDeveloperFaiChecked)}
                  ></Checkbox>
                  Developer FAI
                </p>
              )}
              <FlashButton
                onClick={() => startFlashing(selectedOption)}
                style={{
                  background: flashingButtonActive ? "#e44411" : "rgba(120, 120, 120, 0.6)",
                  cursor: flashingButtonActive ? "pointer" : "not-allowed",
                  marginTop: !canRunDeveloperFai ? "20px" : "0px",
                }}
                disabled={!flashingButtonActive}
              >
                {StartFlashing}
              </FlashButton>
            </div>
          </FlashButtonGrid>
          <ReadyToFlashButtonGrid item xs={12}>
            {readyToFlashDevices}
          </ReadyToFlashButtonGrid>
        </Grid>
      </Grid>
    </SubContainer>
  )
}
