import { Dialog, DialogActions, DialogTitle } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { CreateAxiosConfig } from "pages/AxiosConfig"
import { useEffect, useState } from "react"
import styled from "styled-components"
import {
  CalibrationVersion,
  Close,
  CurrentVersions,
  DeviceMonitoringVersion,
  FactoryBackendVersion,
  FactoryFrontend,
  Version,
} from "variables/sharedVariables"
import packageJson from "../../../package.json"
import { AxiosResponse } from "axios"

const useStyles: any = makeStyles(() => ({
  dialog: {
    height: 400,
    width: 500,
    paddingTop: 30,
    paddingBottom: 20,
    fontSize: 16,
  },
}))

const MainContentDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
`
const LeftContentDiv = styled.div`
  align-self: flex-start;
`
const Versions = styled.p`
  fontweight: bold;
`
const CloseBtn = styled.button`
  height: 30px;
  width: 80px;
  border: 0.5px solid #e44411;
  border-radius: 6px;
  background: white;
  color: #e44411;
  font-weight: bold;
  cursor: pointer;
`
interface VersionsDialogProps {
  open: boolean
  onClick: () => void
}

export const SoftwareVersionsDialog = ({ open, onClick }: VersionsDialogProps) => {
  const styles = useStyles()
  const [backendVersion, setBackendVersion] = useState({ backend_version: "" })
  const [calibrationVersion, setCalibrationVersion] = useState("")
  const axiosAlphasenseFactory = CreateAxiosConfig("/api/v1")
  const axiosCalibrationVersion = CreateAxiosConfig("/calibration-server/production")

  useEffect(() => {
    axiosAlphasenseFactory
      .get("/version")
      .then((response: AxiosResponse) => setBackendVersion(response.data))
    axiosCalibrationVersion
      .get("/version")
      .then((response: AxiosResponse) => setCalibrationVersion(response.data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog open={open} classes={{ paper: styles.dialog }}>
      <DialogTitle
        id="alert-dialog-title"
        style={{ color: "#e44411", textAlign: "center", wordBreak: "break-all" }}
      >
        {CurrentVersions}
        <br />
        {Version}
      </DialogTitle>
      <MainContentDiv>
        <LeftContentDiv>
          <p>{FactoryFrontend}</p>
          <p>{FactoryBackendVersion}</p>
          <p>{CalibrationVersion}</p>
          <p>{DeviceMonitoringVersion}</p>
        </LeftContentDiv>
        <div>
          <Versions>{packageJson.version}</Versions>
          <Versions>{backendVersion.backend_version}</Versions>
          <Versions>{calibrationVersion}</Versions>
        </div>
      </MainContentDiv>
      <DialogActions style={{ margin: "auto" }}>
        <CloseBtn onClick={onClick}>{Close}</CloseBtn>
      </DialogActions>
    </Dialog>
  )
}
