import {
  BottomPaddedSpan,
  CenteredDiv,
  CenteredTitle,
  Div,
  DivWithPadding,
  FlexCenter,
  SuccessTitle,
} from "styles/ProgressReportStyles"
import { ReportProps } from "../CalibrationReport/CalibrationReport"
import { colorForReportValue, translateReportValue } from "utility/helpingFunctions"

export const QualityAssuranceReportPage = ({
  device_type,
  device_id,
  timestamp_utc,
  entities,
  success,
}: ReportProps) => {
  return (
    <DivWithPadding>
      <CenteredTitle>
        {`Quality Assurance report for ${device_type}-${device_id} at ${timestamp_utc}:`}
      </CenteredTitle>
      <SuccessTitle>
        {success ? "All Checks passed successfully" : "Some Checks failed"}
      </SuccessTitle>
      <FlexCenter>
        <CenteredDiv>
          {Object.entries(entities).map(([key, value]) => (
            <>
              {value !== null ? (
                <Div>
                  <BottomPaddedSpan>{key}</BottomPaddedSpan>
                  <span style={{ color: colorForReportValue(String(value)) }}>
                    {translateReportValue(String(value))}
                  </span>
                </Div>
              ) : (
                <></>
              )}
            </>
          ))}
        </CenteredDiv>
      </FlexCenter>
    </DivWithPadding>
  )
}
