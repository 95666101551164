import {
  BottomPaddedSpan,
  CenteredDiv,
  CenteredTitle,
  Div,
  DivWithPadding,
  FlexCenter,
} from "styles/ProgressReportStyles"
import { ReportProps } from "../CalibrationReport/CalibrationReport"
import {
  colorForReportValue,
  getCameraName,
  reportTitle,
  translateReport,
  translateReportValue,
} from "utility/helpingFunctions"
import { configurationReportTranslations } from "utility/translations"
import { ConfigurationReport } from "variables/sharedVariables"

export const ConfigurationReportPage = ({
  device_type,
  device_id,
  timestamp_utc,
  entities,
}: ReportProps) => {
  //TODO: split all the Object into two functions
  // eslint-disable-next-line @typescript-eslint/ban-types
  const keyAndValue = (key: string, value: {}) => {
    if (
      ["odometry_status", "process_error"].includes(key) &&
      ["Unknown", "Ok", "true"].includes(String(value))
    ) {
      return <></>
    } else if (key === "camera_statuses") {
      let isCamerasGood = false
      value &&
        Object.values(value).map((item) => {
          if (!["Unknown", "Ok"].includes(String(item))) {
            return (isCamerasGood = true)
          }
          return isCamerasGood
        })
      return (
        <>
          {isCamerasGood ? (
            <Div>
              <BottomPaddedSpan>
                {translateReport(key, configurationReportTranslations)}
              </BottomPaddedSpan>
            </Div>
          ) : (
            <>
              <Div>
                <BottomPaddedSpan>
                  {translateReport(key, configurationReportTranslations)}
                </BottomPaddedSpan>
                <span style={{ color: colorForReportValue("Ok") }}>
                  {translateReportValue("Ok")}
                </span>
              </Div>
            </>
          )}
          {value &&
            Object.values(value).map((item, index) => (
              <Div key={`${String(item)}-${index}`}>
                {["Unknown", "Ok"].includes(String(item)) ? (
                  <></>
                ) : (
                  <>
                    <BottomPaddedSpan style={{ paddingLeft: "30px" }}>
                      {getCameraName(index)} :
                    </BottomPaddedSpan>
                    <span style={{ color: colorForReportValue(String(value)) }}>
                      {translateReportValue(String(item))}
                    </span>
                  </>
                )}
              </Div>
            ))}
        </>
      )
    } else {
      return (
        <Div>
          <BottomPaddedSpan>
            {translateReport(key, configurationReportTranslations)}
          </BottomPaddedSpan>
          <span style={{ color: colorForReportValue(String(value)) }}>
            {translateReportValue(String(value))}
          </span>
        </Div>
      )
    }
  }
  return (
    <DivWithPadding>
      <CenteredTitle>{ConfigurationReport}</CenteredTitle>
      {reportTitle(device_type, device_id, timestamp_utc)}
      <FlexCenter>
        <CenteredDiv>
          {Object.entries(entities).map(([key, value]) => {
            return <>{value !== null ? <>{value && keyAndValue(key, value)}</> : <></>}</>
          })}
        </CenteredDiv>
      </FlexCenter>
    </DivWithPadding>
  )
}
