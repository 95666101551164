/* eslint-disable max-len */
import { Translations } from "./types"

export const stateTranslations = {
  readyForFlashing: "Ready for Flashing",
  waitingForFlashing: "Waiting for Flashing",
  flashing: "Flashing",
  installingOS: "Installing OS",
  installingSevensenseSoftware: "Installing Sevensense Software",
  completed: "Completed",
}

export const flashingDescriptionTranslations = {
  readyForFlashing:
    "The device is connected to the Flashing Station, powered up and in “Recovery Mode”, ready to be (re-)flashed.",
  waitingForFlashing:
    "The flashing task has been issued for this device. The device is waiting for the flashing to commence.",
  flashing: "Flashing of the NVIDIA Jetson board is currently ongoing.",
  identifyingDevice: "Identifying Device",
  installingOS:
    "Disk partitions are configured and the operating system is being installed and configured.",
  installing: "Installing Software",
  installingSevensenseSoftware: "Installing Sevensense software packages.",
  completed:
    "The device successfully completed the flashing and software installation process. It can now be taken off the Flashing Station.",
  failed: "A failure has occurred.",
  failedAcknowledged: "A failure has occurred. The failure has been acknowledged by a user.",
  unavailable: "No state information available for this device. ",
}

export const bootstrappingDescriptionTranslations = {
  configuration: "The robot is undergoing configuration of network and sensors.",
  calibration: "The robot is calibrating its sensors in the calibration arena.",
  acceptance: "The robot is undergoing final validation checks. ",
  completedBootstrap: "The Alphasense Factory process of this robot has completed successfully.",
  failedBootstrap: "A failure has occurred.",
  unavailableBootstrap: "No state information available for this robot.",
  unknown: "The state of this robot is unknown.",
  clickHere: "Click here ",
  report: "to see the report.",
}

export const calibrationReportTranslations: Translations = {
  attitude: "Attitude",
  cam0: "Camera Port 1",
  cam1: "Camera Port 2",
  cam2: "Camera Port 3",
  cam3: "Camera Port 4",
  cam4: "Camera Port 5",
  cam5: "Camera Port 6",
  cam6: "Camera Port 7",
  cam7: "Camera Port 8",
  fused_o3xs: "Obstacle Map Quality",
  imu: "IMU",
  o3x_center: "Center IFM",
  o3x_left: "Left IFM",
  o3x_right: "Right IFM",
  odometry: "Wheel Odometry",
  trajectory_length: "Distance Driven",
  ultrasonics_pair_11_12: "Ultrasonics Pair 11-12",
  ultrasonics_pair_1_2: "Ultrasonics Pair 1-2",
  ultrasonics_pair_3_4: "Ultrasonics Pair 3-4",
  ultrasonics_pair_5_6: "Ultrasonics Pair 5-6",
  ultrasonics_pair_7_8: "Ultrasonics Pair 7-8",
  ultrasonics_pair_9_10: "Ultrasonics Pair 9-10",
}
export const configurationReportTranslations: Translations = {
  alphasense_driver: "Alphasense Driver",
  camera_statuses: "Cameras",
  odometry_status: "Odometry",
  ultrasonic_statuses: "Ultrasonic Statuses",
  mobile_connection_status: "Mobile Connection Status",
  process_error: "Process Error",
}

export const CalibrationSubStates: Translations = {
  AutoCalibrationRegistration: "Initializing Calibration",
  WaitingForInitialCalibration: "Initializing Calibration",
  StartQRReader: "Initializing Calibration",
  WaitingForHumanOperator: "Please move the robot into the Calibration Arena",
  StopQRReader: "Ready to start data recording",
  RotateInPlace: "Recording calibration data",
  PreCalibDataUploading: "Recording calibration data",
  WaitingForPreCalibrationResult: "Recording calibration data",
  CalibrationRepeat: "Recording calibration data",
  DataUploading: "Uploading calibration data",
  UploadingFactoryCalibrationData: "Uploading calibration data",
  WaitingForCalibrationResult: "Waiting for calibration result",
  UpdateMap: "Waiting for calibration result",

  ReadyForManualCalibration: "Initializing Calibration",
  AutoStartByBootstrap: "Initializing Calibration",
  AstiSemiAutomaticCalibration: "Initializing Calibration",
  WaitingForArenaLocalization: "Please move the robot into the Calibration Arena",
  ManualDataRecording: "Recording calibration data",
  LocalPreprocessing: "Pre-processing calibration data",
  SuccessfullyCompleted: "Calibration completed successfully",

  LocallyExtractingFactoryCalibrationData: "Pre-processing calibration data",
  WaitingForFactoryCalibration: "Waiting for Calibration Result",
  WaitingForRotateInPlaceCalibration: "Waiting for calibration result",
  WaitingForInitialConfiguration: "Initializing Calibration",

  PreCalibSanityCheckFailed: "Calibration checks failed",
  SanityCheckFailed: "Calibration checks failed",
  SanityChecksFailedForFactory: "Calibration checks failed",
  Aborted: "Calibration aborted by robot",
  ServerCalledAbort: "Calibration aborted by server",
  Error: "Error",

  StartCalibration: "Connecting to calibration server",
  ready_for_recording: "Ready to record data",
  recording_completed: "Recording completed",

  LocallyExtractingRotateInPlaceCalibrationData: "Recording calibration data",
  RotateInPlaceDataCheck: "Recording calibration data",
  UploadingRotateInPlaceCalibrationData: "Recording calibration data",
  RecordingCalibrationData: "Recording calibration data",
  LocallyExtractingFullFactoryCalibrationData: "Pre-processing calibration data",
  FullFactoryDataCheck: "Pre-processing calibration data",
  UploadingFullFactoryCalibrationData: "Uploading calibration data",
  WaitingForFullFactoryCalibration: "Waiting for calibration result",
}

export const ReportValues: Translations = {
  Ok: "OK",
  true: "OK",
  NotConnected: "Not Connected",
  FaultyConnection: "Faulty Connection",
  Unknown: "Unknown",
  Missing: "Missing",
  NoModuleDetected: "No Module Detected",
  NoConnection: "No Connection",
  ConfigureFailed: "Sub-step Configure Failed",
  UpgradeFailed: "Sub-step Upgrade Failed",
  false: "Error",
  TransmissionError: "Transmission Error",
}

export const HelperTextValues: Translations = {
  camera_focus: "Check camera focus",
  camera_coverage: "Check if the camera lens is scratched or if the camera is obstructed",
  imu_mounting: "High vibrations detected. Check robot assembly.",
  wheel_odometry_quality: "Verify wheel encoder is behaving nominally and/or verify the robot",
  steering_offset: "Check steering offset",
  correctly_connected_cameras:
    "Verify that the cameras are correctly connected. Their position changed by ${value}m compared to the reference robot, but must not change more then ${max}m",
  stereo_alignment:
    "Please make sure that the stereo pairs are well aligned and correctly connected.",
  minimum_trajectory: "Ensure that the robot drives at least ${min} m",
  ultrasonics_cabling: "Verify that all ultrasonic sensors are connected correctly",
}
