import { ArrowDropDown, ArrowDropUp, RadioButtonUnchecked } from "@mui/icons-material"
import { PaddingLeftDiv, RowAlignCenterDiv } from "styles/ReportsStyle"
import { CheckValues } from "./CheckValues"
import { CheckResults } from "types/calibrationReportTypes"
import { useState } from "react"

interface CheckProps {
  check: string
  checkDetails: CheckResults
}

export const Check = ({ check, checkDetails }: CheckProps) => {
  const [showSubcheck, setShowSubcheck] = useState(false)

  const hasNoSubcheckName = Object.entries(checkDetails[check]).some(
    ([subcheck]) => subcheck === "NO_SUBCHECK_NAME",
  )

  return (
    <div>
      <RowAlignCenterDiv
        onClick={() => setShowSubcheck((prevValue) => !prevValue)}
        style={hasNoSubcheckName ? { cursor: "auto" } : { cursor: "pointer" }}
      >
        <RadioButtonUnchecked style={{ color: "red", marginRight: "10px" }} />
        <span style={hasNoSubcheckName ? { color: "red", fontWeight: "bold" } : {}}>{check}</span>
        {!hasNoSubcheckName &&
          (showSubcheck ? (
            <ArrowDropUp style={{ color: "lightgray", fontSize: "30px" }} />
          ) : (
            <ArrowDropDown style={{ color: "lightgray", fontSize: "30px" }} />
          ))}
      </RowAlignCenterDiv>

      {Object.entries(checkDetails[check]).map(([subcheck, subcheckDetail]) => (
        <div>
          {subcheck !== "NO_SUBCHECK_NAME" ? (
            <PaddingLeftDiv>
              {showSubcheck && (
                <>
                  <RowAlignCenterDiv>
                    <RadioButtonUnchecked
                      style={{ color: "red", marginRight: "10px", cursor: "auto" }}
                    />
                    <span style={{ color: "red", fontWeight: "bold" }}>{subcheck}</span>
                  </RowAlignCenterDiv>
                  <CheckValues {...subcheckDetail} />
                </>
              )}
            </PaddingLeftDiv>
          ) : (
            <CheckValues {...subcheckDetail} />
          )}
        </div>
      ))}
    </div>
  )
}
