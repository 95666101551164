import { Info } from "@mui/icons-material"
import { ExpectedValueDiv, HelperTextDiv, ValuesDiv } from "styles/ReportsStyle"
import { CheckDetails } from "types/calibrationReportTypes"
import { HelperTextValues } from "utility/translations"
import { Value, Expected } from "variables/sharedVariables"
import { CustomSlider } from "../../Components/CustomSlider/CustomSlider"

export const parseTwoDecimals = (value: number) => parseFloat(value.toString()).toPrecision(2)

const displayHelperText = (checkDetail: CheckDetails, cause: string) => {
  let currentHelperText = HelperTextValues[cause]
  if (checkDetail?.expected) {
    currentHelperText = currentHelperText.replace("${expected}", checkDetail.expected.join(", "))
    if (checkDetail?.value) {
      currentHelperText = currentHelperText.replace(
        "${value}",
        (checkDetail.value as string[]).join(", "),
      )
    }
  } else {
    if (checkDetail?.lowerBound) {
      currentHelperText = currentHelperText.replace(
        "${min}",
        parseTwoDecimals(checkDetail.lowerBound),
      )
    }
    if (checkDetail?.upperBound) {
      currentHelperText = currentHelperText.replace(
        "${max}",
        parseTwoDecimals(checkDetail.upperBound),
      )
    }
    if (checkDetail?.value) {
      currentHelperText = currentHelperText.replace(
        "${value}",
        parseTwoDecimals(checkDetail.value as number),
      )
    }
  }

  return currentHelperText
}

export const CheckValues = (checkDetail: CheckDetails) => (
  <>
    <ValuesDiv>
      {(checkDetail.lowerBound || checkDetail.upperBound) && (
        <CustomSlider
          value={checkDetail.value as number}
          min={checkDetail.lowerBound}
          max={checkDetail.upperBound}
        />
      )}
      {checkDetail.expected && checkDetail.expected.length > 0 && (
        <ExpectedValueDiv>
          <span style={{ fontWeight: 500 }}>
            {Value}: {(checkDetail.value as string[]).join(", ")}
          </span>
          <span>
            {Expected}: {checkDetail.expected.join(", ")}
          </span>
        </ExpectedValueDiv>
      )}
    </ValuesDiv>
    {checkDetail.possibleCauses &&
      checkDetail.possibleCauses.map((cause) => (
        <HelperTextDiv>
          <Info style={{ color: "lightgray" }} />
          <span style={{ fontWeight: "bold" }}>{displayHelperText(checkDetail, cause)}</span>
        </HelperTextDiv>
      ))}
  </>
)
