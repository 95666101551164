import {
  ArrowDropDown,
  ArrowDropUp,
  CheckCircleOutline,
  RadioButtonUnchecked,
} from "@mui/icons-material"
import { useMemo, useState } from "react"
import { CenteredTitle, DivWithPadding } from "styles/ProgressReportStyles"
import { RowAlignCenterDiv } from "styles/ReportsStyle"
import { FailedCheckResults } from "types/calibrationReportTypes"
import { reportTitle } from "utility/helpingFunctions"
import { CalibrationReport } from "variables/sharedVariables"
import { SensorCheck } from "./SensorCheck"
import { Check } from "./Check"

const hasFailedCameras = (failed_check_results?: FailedCheckResults, sensors?: string[]) => {
  return sensors?.some(
    (sensor) =>
      sensor.startsWith("cam") &&
      failed_check_results?.[sensor] &&
      Object.keys(failed_check_results[sensor]).length > 0,
  )
}

export interface ReportProps {
  device_id: string
  device_type: string
  timestamp_utc: string
  success: boolean
  report_type: string
  entities?: any
  sensors?: string[]
  failed_check_results?: FailedCheckResults
}

export const CalibrationReportPage = ({
  device_type,
  device_id,
  timestamp_utc,
  sensors,
  failed_check_results,
}: ReportProps) => {
  const [showCameras, setShowCameras] = useState(false)

  const filteredSensors = useMemo(
    () => sensors?.filter((sensor) => sensor !== "NO_SENSOR"),
    [sensors],
  )

  const failedCameras = hasFailedCameras(failed_check_results, sensors)

  return (
    <DivWithPadding>
      <CenteredTitle>{CalibrationReport}</CenteredTitle>
      {reportTitle(device_type, device_id, timestamp_utc)}
      <div style={{ padding: "0 70px", height: "65vh", overflow: "auto" }}>
        <RowAlignCenterDiv
          onClick={() => setShowCameras((prevValue) => !prevValue)}
          style={failedCameras ? { cursor: "pointer" } : {}}
        >
          {failedCameras ? (
            <RadioButtonUnchecked style={{ color: "red", marginRight: "10px" }} />
          ) : (
            <CheckCircleOutline style={{ color: "green", marginRight: "10px" }} />
          )}
          <span>Cameras</span>
          {failedCameras &&
            (showCameras ? (
              <ArrowDropUp style={{ color: "lightgray", fontSize: "30px" }} />
            ) : (
              <ArrowDropDown style={{ color: "lightgray", fontSize: "30px" }} />
            ))}
        </RowAlignCenterDiv>

        {showCameras &&
          filteredSensors &&
          filteredSensors
            .filter((sensor) => sensor.startsWith("cam"))
            .map((sensor) => (
              <SensorCheck
                key={sensor}
                sensor={sensor}
                failed_check_results={failed_check_results}
              />
            ))}

        {filteredSensors &&
          filteredSensors
            .filter((sensor) => !sensor.startsWith("cam"))
            .map((sensor) => (
              <SensorCheck
                key={sensor}
                sensor={sensor}
                failed_check_results={failed_check_results}
              />
            ))}

        {failed_check_results?.["NO_SENSOR"] &&
          Object.entries(failed_check_results["NO_SENSOR"]).map(([check]) => (
            <Check
              key={`check-${check}`}
              check={check}
              checkDetails={failed_check_results["NO_SENSOR"]}
            />
          ))}
      </div>
    </DivWithPadding>
  )
}
