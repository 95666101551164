/* eslint-disable max-len */
export const MainTitle = "Alphasense Factory"
export const WelcomeNote = "Welcome to Alphasense Factory UI"

export const Login = "LOGIN"
export const Username = "Username"
export const Password = "Password"
export const AuthError = "Incorrect username or password. Please check and try again."

export const ResetPassword = "to reset password"
export const ChangePassword = "Change Password"
export const NewPassword = "New Password"
export const ConfirmPassword = "Confirm Password"
export const Save = "Save"
export const PasswordSaved = "Password saved successfully"
export const Click = "Click here"
export const AuthFailedError = "Request Error"

export const FlashingStationTitle = "FLASHING STATION"
export const Bootstrapping = "BOOTSTRAPPING"
export const Logged = "LOGGED:"
export const Logout = "Logout"
export const VersionInfo = "Version Info"
export const CurrentVersions = "CURRENT RUNNING "
export const Version = "VERSIONS"
export const FactoryFrontend = "Factory Frontend:"
export const FactoryBackendVersion = "Factory Backend:"
export const DeviceMonitoringVersion = "Device Monitoring:"
export const CalibrationVersion = "Calibration:"
export const Close = "CLOSE"

export const ReadyForFlash = "READY TO FLASH"
export const StartFlashing = "START FLASHING"
export const WaitingForFlashing = "WAITING FOR FLASH"
export const InProgressTitle = "IN PROGRESS"
export const Show = "SHOW DETAILS"
export const Hide = "HIDE DETAILS"
export const Next = "NEXT"
export const Pinned = "PINNED"

export const FinishedTitle = "FINISHED"

export const Date = "DATE/TIME"
export const State = "STATE"
export const Description = "DESCRIPTION"
export const SubStateTitle = "SUBSTATE"
export const Status = "STATUS"

export const ClearAll = "CLEAR ALL"
export const ErrorNotificationTitle = "NOTIFICATION"
export const AcknowledgeButton = "ACKNOWLEDGE"
export const CancelButton = "CANCEL"
export const Proceed = "PROCEED"
export const RepeatCalibration = "REPEAT CALIBRATION"
export const ProcessReport = "Report: "
export const Link = "Link"
export const Skip = "SKIP"
export const AcceptAnyway = "ACCEPT ANYWAY"
export const SkipAndProceed = "SKIP AND PROCEED"
export const ConfirmationForSkip =
  "This action will skip the failed setup step and ignore the error. Be aware, that by doing so your device might not be completely configured. Are you sure you want to ignore the error and proceed with the next step?"

export const ActionRequired = "Action Required: "
export const StartBootstrappingMessage = "Press START to initiate bootstrapping"
export const SoftwareVersion = "software_version"
export const CalibrationState = "calibration_state"
export const CalibrationResultPath = "calibration_result_path"
export const FaiVersion = "fai_version"

export const Cancel = "CANCEL"
export const ClearAllDevices = "Clear all devices"
export const ConfirmationMessage =
  "Are you sure you want to clear all completed devices? Once cleared you can no longer view their factory history and reports!"

export const Calibration = "Calibration"
export const Localization = "Localization"
export const Odometry = "Odometry"
export const PerceptionSensors = "Perception Sensors"
export const PerformanceMonitor = "Performance Monitor"
export const VelocityTracking = "Velocity Tracking"
export const AlphasenseCameraSystems = "Alphasense Camera Systems:"
export const SensorDataStreams = "Sensor Data Streams:"
export const OdometryStatus = "Odometry status:"
export const UltrasonicConnectionStatus = "Ultrasonic connection status:"
export const MobileInternetConnection = "Mobile Internet Connection:"
export const Connection = "4G Connection:"

export const InActiveDevice = "The device has disconnected from the server."
export const ExecutionOfScript = "Execution of script"
export const CompletedWithExit = "completed with exit code 0"
export const CompletedSuccessfully = "completed successfully"

export const Start = "START"
export const Stop = "STOP"
export const OdometrySignal = "Odometry Signal:"
export const CameraImages = "Camera Images:"

export const ConfirmationTitle = "Abort bootstrapping"
export const ConfirmationDescription =
  "Are you sure you want to abort bootstrapping? This will completely reset the state of the device. Any recorded data is lost and the factory process needs to be completed from the very beginning!"
export const Abort = "ABORT"

export const ConfigurationReport = "Configuration Report"
export const CalibrationReport = "Calibration Report"
export const Device = "Device:"
export const Timestamp = "Timestamp:"
export const SoftwareReleaseVersion = "Software Version :"

export const Value = "Value"
export const Min = "Min"
export const Max = "Max"

export const Offline = "Offline"

export const RobotIdentity = "Robot Identity"
export const Expected = "Expected"

export const ShareWith = "SHARE WITH"
export const ViewReport = "VIEW REPORT"
export const Send = "SEND"
export const ShareWith7SPlaceholder =
  "Please describe the issue you are experiencing with the device and/or any additional information that might be relevant"
