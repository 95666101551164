import { Tooltip } from "@mui/material"
import { CreateAxiosConfig } from "pages/AxiosConfig"
import {
  ConfirmationDialogBox,
  callClearAllDevicesEndpoint,
} from "Components/ConfirmationDialogBox"
import { SyntheticEvent, useEffect, useState } from "react"
import { useNotify } from "react-admin"
import { SubContainer } from "styles/CommonStyles"
import {
  ClearAllButton,
  CloseButton,
  DetailViewDiv,
  FinishedButton,
  FinishedDevices,
} from "styles/FlashStationStyle/FinishedStyle"
import { Title } from "styles/FlashStationStyle/ReadyToFlashStyle"
import {
  lastIndexOfCalibrationTransitions,
  mapBackendTransitionsToFrontendTransitions,
  splitDeviceName,
  slicedTransitions,
} from "utility/helpingFunctions"
import {
  ClearAll,
  ClearAllDevices,
  ConfirmationMessage,
  FinishedTitle,
  SoftwareVersion,
} from "variables/sharedVariables"
import { BootstrappingDataProps } from "./InProgressSession"
import { DetailView } from "Components/DetailView/DetailViewWindow"
import { useDeviceStateHook } from "utility/getDeviceState"
import { BackendTransition, DeviceWithTransitions } from "utility/types"
import { getPinnedDevices } from "utility/localStorage"
import { AxiosResponse } from "axios"

export const BootstrappingFinishedSession = ({
  bootstrappingDevices,
  activeFilters,
}: BootstrappingDataProps) => {
  const [showDetails, setShowDetails] = useState<string | null>(null)
  const [openDialogBox, setOpenDialogBox] = useState(false)
  const notify = useNotify()
  const axiosAlphasenseFactory = CreateAxiosConfig("/api/v1")
  const axiosDeviceMonitoring = CreateAxiosConfig("/device-monitoring/api/v2")
  const [filteredTransitions, setFilteredTransitions] = useState<BackendTransition[]>([])
  const [calibrationStateTransitions, setCalibrationStateTransitions] = useState<
    BackendTransition[]
  >([])
  const { updateDeviceMonitoringState, deviceMonitoringStates } = useDeviceStateHook()
  const ifBootstrapPage = true
  const pinnedIds = getPinnedDevices()

  const filteredFinishedDevices = bootstrappingDevices.filter(
    (data: DeviceWithTransitions) =>
      !data.is_bootstrapping_active && !pinnedIds.includes(data.hostname),
  )

  let devicesToDisplay = filteredFinishedDevices
  activeFilters.forEach((element) => {
    devicesToDisplay = element(devicesToDisplay)
  })

  useEffect(() => {
    updateDeviceMonitoringState(filteredFinishedDevices, SoftwareVersion)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredFinishedDevices.map((device) => device.hostname || "").join(",")])

  const showDetailsOnClick = (deviceName: string) => {
    const newDeviceClicked = showDetails !== deviceName
    setShowDetails(newDeviceClicked ? deviceName : null)
    if (newDeviceClicked) {
      axiosDeviceMonitoring
        .get(`/device_transitions/${deviceName}/bootstrap-progress?last_n=100`)
        .then((response: AxiosResponse) => {
          setFilteredTransitions(slicedTransitions(response.data))
        })
      axiosDeviceMonitoring
        .get(`/device_transitions/${deviceName}/calibration_state?last_n=20`)
        .then((response: AxiosResponse) => {
          const transitionFromEmptyMessage = lastIndexOfCalibrationTransitions(response.data)
          const transitionsAfterFilter = response.data.slice(transitionFromEmptyMessage)
          setCalibrationStateTransitions(transitionsAfterFilter)
        })
    }
  }

  const hideButton = async (deviceName: string) => {
    await axiosAlphasenseFactory
      .delete(`/bootstrapping/${deviceName}`)
      .catch(() => notify("Failed to delete device"))
  }
  const canClearAll =
    filteredFinishedDevices.length > 0 && filteredFinishedDevices.length === devicesToDisplay.length
  return (
    <>
      <SubContainer>
        <Title>{FinishedTitle}</Title>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ClearAllButton
            disabled={!canClearAll}
            style={{
              background: !canClearAll ? "rgba(120, 120, 120, 0.6)" : "#e44411",
              cursor: !canClearAll ? "not-allowed" : "pointer",
            }}
            onClick={() => setOpenDialogBox(true)}
          >
            {ClearAll}
          </ClearAllButton>
        </div>
        <FinishedDevices>
          {devicesToDisplay.map((data: DeviceWithTransitions) => {
            const isTransitionLength = data.transitions.length > 0
            const isStateFailed =
              isTransitionLength && data.transitions[data.transitions.length - 1].severity > 0
            const backendToFrontendTransition = mapBackendTransitionsToFrontendTransitions(
              data.is_bootstrapping_active,
              filteredTransitions,
              calibrationStateTransitions,
            )
            const softwareVersion = deviceMonitoringStates
              .get(SoftwareVersion)
              ?.get(data.hostname)?.message

            return (
              <>
                <div>
                  <Tooltip title={""}>
                    <FinishedButton
                      key={data.hostname}
                      style={{ background: isStateFailed ? "#AF1A1A" : "green", cursor: "pointer" }}
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      onClick={() => showDetailsOnClick(data.hostname!)}
                    >
                      <CloseButton
                        key={data.hostname}
                        onClick={(e: SyntheticEvent) => {
                          e.stopPropagation()
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          hideButton(data.hostname!)
                        }}
                      >
                        X
                      </CloseButton>
                      {splitDeviceName(data.hostname || "unknown")}
                    </FinishedButton>
                  </Tooltip>
                  {showDetails === data.hostname ? (
                    <DetailViewDiv>
                      <DetailView
                        hostName={data.hostname}
                        showReportDropDown={true}
                        transitions={backendToFrontendTransition}
                        {...{ ifBootstrapPage, softwareVersion }}
                      />
                    </DetailViewDiv>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )
          })}
        </FinishedDevices>
        {openDialogBox && (
          <ConfirmationDialogBox
            title={ClearAllDevices}
            message={ConfirmationMessage}
            buttonText={ClearAll}
            onConfirm={() => callClearAllDevicesEndpoint("/bootstrapping", notify)}
            {...{ openDialogBox, setOpenDialogBox }}
          />
        )}
      </SubContainer>
    </>
  )
}
