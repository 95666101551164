import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material"
import CloseIcon from "assets/icons/CloseIcon.svg"
import { logOut } from "utility/user_authentication"
import { CreateAxiosConfig } from "pages/AxiosConfig"
import { useRedirect } from "react-admin"
import { useState } from "react"
import axios, { AxiosError, AxiosResponse } from "axios"
import { SubmitShareWith7SButton } from "styles/DetailViewStyle"
import { Send, ShareWith7SPlaceholder } from "variables/sharedVariables"

interface ShareWith7SDialogProps {
  deviceName: string
  onClose: () => void
}

export const ShareWith7SDialog = ({ deviceName, onClose }: ShareWith7SDialogProps) => {
  const axiosAlphasenseFactory = CreateAxiosConfig("/api/v1")
  const redirect = useRedirect()
  const [message, setMessage] = useState<string>()

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    if (!message?.trim()) {
      setMessage("")
      return
    }

    axiosAlphasenseFactory
      .post(`send_support_request/${deviceName}`, { message })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          onClose()
        }
      })
      .catch((e: Error | AxiosError) => {
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 401 || e.response?.status === 502) {
            logOut(redirect)
            return null
          }
        } else {
          console.error("Unexpected error:", e)
        }
      })
  }

  return (
    <Dialog open={!!deviceName}>
      <form style={{ width: "500px" }} onSubmit={(e) => onSubmit(e)}>
        <DialogTitle sx={{ fontWeight: "600", color: "#e44411" }}>
          Share with Sevensense
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <img src={CloseIcon} alt="Close" />
        </IconButton>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Message *"
            onChange={(e) => setMessage(e.target.value)}
            error={message === ""}
            helperText={message === "" ? "This field is required!" : ""}
            placeholder={ShareWith7SPlaceholder}
          />
        </DialogContent>
        <DialogActions sx={{ margin: "auto", justifyContent: "center" }}>
          <SubmitShareWith7SButton type="submit">{Send}</SubmitShareWith7SButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
