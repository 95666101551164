import { TitleContainer } from "../../styles/FlashStationStyle/ReadyToFlashStyle"
import { NavBar } from "../../Components/NavBar/NavBarMenu"
import { AuthFailedError, Bootstrapping } from "../../variables/sharedVariables"
import { useEffect, useState } from "react"
import {
  BootstrapStateInfo,
  BootstrappingDevice,
  BootstrappingTransition,
  DeviceWithTransitions,
} from "../../utility/types"
import { CreateAxiosConfig } from "../AxiosConfig"
import {
  CommonDivContainer,
  CommonSessionContainer,
  CommonTitleDiv,
  MainDiv,
  MainTitle,
} from "styles/CommonStyles"
import {
  BootstrappingInProgressSession,
  Filter,
} from "../../Components/Bootstrapping/InProgressSession"
import { BootstrappingFinishedSession } from "../../Components/Bootstrapping/FinishedSession"
import { useNotify, useRedirect } from "react-admin"
import { ActionRequiredSession } from "Components/Bootstrapping/ActionRequiredSession"
import { WebSocketRequest } from "Provider/websocket"
import { WebSocketProvider } from "Provider/WebSocketProvider"
import axios, { AxiosError } from "axios"
import { logOut } from "utility/user_authentication"
import { Input } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { DeviceMonitoringState } from "utility/getDeviceState"
import { PinnedSession } from "Components/Bootstrapping/PinnedSession"
import { getPinnedDevices } from "utility/localStorage"

const loadBootstrapStateInfo = (
  lastTransition?: BootstrappingTransition,
): BootstrapStateInfo | undefined => {
  if (!lastTransition) {
    return undefined
  }
  try {
    return JSON.parse(lastTransition.detail)
  } catch (error) {
    console.error("Failed to load the bootstrapping state info:", error)
    return undefined
  }
}

export const BootstrappingPage = () => {
  const notify = useNotify()
  const redirect = useRedirect()
  const ifBootstrapPage = true
  const axiosAlphasenseFactory = CreateAxiosConfig("/api/v1")
  const axiosDeviceMonitoring = CreateAxiosConfig("/device-monitoring/api/v2")
  const [bootstrappingDevices, setBootstrappingDevices] = useState<DeviceWithTransitions[]>([])
  const { images, deviceHeartBeats, odometry } = WebSocketRequest()
  const [activeFilters, setActiveFilters] = useState(new Map<string, Filter>())
  const [currentFilterValue, setCurrentFilterValue] = useState("")
  const [pinnedDevicesUpdateTime, setPinnedDevicesUpdateTime] = useState<number>(
    new Date().getTime(),
  )
  const prevPinnedDeviceIds = getPinnedDevices()
  const [pinnedIds, setPinnedIds] = useState<string[]>(() => {
    const ids = getPinnedDevices()
    return ids
  })

  const onFilterNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentFilterValue(e?.target.value)
    setActiveFilters((old_state) => {
      const filterKey = "nameSelectFilter"
      if (e?.target.value === "") {
        old_state.delete(filterKey)
      } else {
        old_state.set(filterKey, (input: DeviceWithTransitions[]) => {
          return input.filter(
            (item) =>
              item.hostname.includes(e?.target.value.replace(" ", "-")) ||
              item.robot_serial_no?.includes(e?.target.value.replace(" ", "-")),
          )
        })
      }
      return old_state
    })
  }

  const updateDevicesInBootstrapping = async () => {
    try {
      const responseFromBootstrappingEndpoint = await axiosAlphasenseFactory
        .get("/bootstrapping")
        .catch((e: Error | AxiosError) => {
          if (axios.isAxiosError(e)) {
            if (e.response?.status === 401 || e.response?.status === 502) {
              notify(AuthFailedError, { type: "error" })
              logOut(redirect)
              return null
            }
          } else {
            console.error("Unexpected error:", e)
          }
        })
      if (!responseFromBootstrappingEndpoint) {
        //Early exit because redirected anyways
        return
      }
      const bootstrappingDevices: BootstrappingDevice[] = responseFromBootstrappingEndpoint.data
      //We get all the data here in one request to create less traffic
      const activeDevices = bootstrappingDevices
        .filter(
          (device) =>
            device.is_bootstrapping_active || prevPinnedDeviceIds.includes(device.hostname),
        )
        .map((device) => device.hostname)

      //TODO maybe need to do something about errors
      const bootstrappingStatesResponse = await axiosDeviceMonitoring.get(
        `/topic_states/bootstrap-progress`,
        { params: { devices: activeDevices.join(",") } },
      )
      const bootstrappingStates: Map<string, DeviceMonitoringState> = new Map(
        Object.entries(bootstrappingStatesResponse.data),
      )
      const validDevicesInBootstrapping = Array.from(bootstrappingStates)
        .filter(([_, state]) => state.message !== "")
        .map(([hostname, _]) => hostname)
      const bootstrappingProgressPromise = axiosDeviceMonitoring.get(
        `/topic_transitions/bootstrap-progress`,
        { params: { devices: validDevicesInBootstrapping.join(","), last_n: 100 } },
      )
      const calibrationStatePromise = axiosDeviceMonitoring.get(
        `/topic_transitions/calibration_state`,
        { params: { devices: validDevicesInBootstrapping.join(","), last_n: 20 } },
      )
      const bootstrappingProgresses: Map<string, BootstrappingTransition[]> = new Map(
        Object.entries((await bootstrappingProgressPromise).data),
      )
      const calibrationProgresses: Map<string, BootstrappingTransition[]> = new Map(
        Object.entries((await calibrationStatePromise).data),
      )
      const devicesWithTransitions = bootstrappingDevices.map(
        (device: BootstrappingDevice): DeviceWithTransitions | null => {
          try {
            if (device.is_bootstrapping_active || prevPinnedDeviceIds.includes(device.hostname)) {
              if (!validDevicesInBootstrapping.includes(device.hostname)) {
                //We anyways don't display those so better filter them out now
                return null
              }
              const lastHeartBeat = deviceHeartBeats.get(device.hostname)
              const transitions = bootstrappingProgresses.get(device.hostname) || []
              const calibrationTransitions = calibrationProgresses.get(device.hostname) || []
              const bootstrapStateInfo = loadBootstrapStateInfo(transitions[transitions.length - 1])
              return {
                hostname: device.hostname,
                is_bootstrapping_active: device.is_bootstrapping_active,
                isDeviceActive:
                  lastHeartBeat !== undefined &&
                  Date.now() - lastHeartBeat <
                    (bootstrapStateInfo?.shutdown_pending ? 3 * 60 * 1000 : 15 * 1000),
                transitions,
                calibrationTransitions,
                bootstrapStateInfo,
                robot_model_name: device.robot_model_name,
                robot_serial_no: device.robot_serial_no,
              }
            } else {
              return {
                hostname: device.hostname,
                is_bootstrapping_active: device.is_bootstrapping_active,
                isDeviceActive: false,
                transitions: [],
                calibrationTransitions: [],
                bootstrapStateInfo: undefined,
                robot_model_name: device.robot_model_name,
                robot_serial_no: device.robot_serial_no,
              }
            }
          } catch (error) {
            const msg = `Error fetching data for ${device.hostname}`
            notify(msg)
            console.error(msg)
            throw error
          }
        },
      )
      setBootstrappingDevices(
        devicesWithTransitions.filter((data) => data !== null) as DeviceWithTransitions[],
      )
    } catch (error) {
      console.error("Error fetching bootstrapping devices", error)
    }
  }

  useEffect(() => {
    updateDevicesInBootstrapping()
    const interval = setInterval(() => {
      updateDevicesInBootstrapping()
    }, 2000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <WebSocketProvider
      value={{
        deviceHeartBeats,
        images,
        odometry,
      }}
    >
      <CommonDivContainer>
        <NavBar />
        <MainDiv>
          <CommonTitleDiv>
            <TitleContainer>
              <Grid2 container>
                <Grid2 xs={8}>
                  <MainTitle>{Bootstrapping}</MainTitle>
                </Grid2>
                <Grid2
                  container
                  xs={4}
                  alignContent="center"
                  justifyContent="flex-end"
                  direction="row"
                >
                  <Input
                    name="deviceNameFilter"
                    value={currentFilterValue}
                    onChange={onFilterNameChange}
                    placeholder="Filter by device name"
                  />
                </Grid2>
              </Grid2>
            </TitleContainer>
          </CommonTitleDiv>
          <CommonSessionContainer>
            <PinnedSession
              {...{
                bootstrappingDevices,
                activeFilters,
                pinnedIds,
                setPinnedIds,
                pinnedDevicesUpdateTime,
                setPinnedDevicesUpdateTime,
              }}
              onChangeRobotIdentity={updateDevicesInBootstrapping}
            />
          </CommonSessionContainer>
          <CommonSessionContainer>
            <ActionRequiredSession
              {...{
                bootstrappingDevices,
                ifBootstrapPage,
                activeFilters,
                pinnedIds,
                setPinnedIds,
                pinnedDevicesUpdateTime,
                setPinnedDevicesUpdateTime,
              }}
              onChangeRobotIdentity={updateDevicesInBootstrapping}
            />
          </CommonSessionContainer>
          <CommonSessionContainer>
            <BootstrappingInProgressSession
              {...{
                bootstrappingDevices,
                ifBootstrapPage,
                activeFilters,
                pinnedIds,
                setPinnedIds,
                pinnedDevicesUpdateTime,
                setPinnedDevicesUpdateTime,
              }}
              onChangeRobotIdentity={updateDevicesInBootstrapping}
            />
          </CommonSessionContainer>
          <CommonSessionContainer style={{ marginBottom: "50px" }}>
            <BootstrappingFinishedSession
              {...{
                bootstrappingDevices,
                ifBootstrapPage,
                activeFilters,
                pinnedIds,
                setPinnedIds,
                pinnedDevicesUpdateTime,
                setPinnedDevicesUpdateTime,
              }}
            />
          </CommonSessionContainer>
        </MainDiv>
      </CommonDivContainer>
    </WebSocketProvider>
  )
}
