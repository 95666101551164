import { CircularProgress, Snackbar } from "@mui/material"
import { Description } from "@mui/icons-material"
import { CreateAxiosConfig } from "pages/AxiosConfig"
import { useState } from "react"
import { useRedirect } from "react-admin"
import {
  DivForButtons,
  ProceedButton,
  RepeatButton,
  ReportButton,
  ReportShareButtonsRow,
} from "styles/DetailViewStyle"
import { currentState } from "utility/helpingFunctions"
import { DeviceStatus, EState, isFailureStatus } from "utility/types"
import { logOut } from "utility/user_authentication"
import {
  Skip,
  Proceed,
  RepeatCalibration,
  SkipAndProceed,
  ConfirmationForSkip,
  AcceptAnyway,
  ShareWith,
  ViewReport,
} from "variables/sharedVariables"
import { DeviceDetailProps } from "./DetailedViewOfTheState"
import { ConfirmationDialogBox } from "Components/ConfirmationDialogBox"
import logo from "../../assets/images/7s-logo.png"
import { useReportHook } from "utility/useReportHook"
import { ReportPage } from "pages/BootstrappingReports/Reports"
import { ShareWith7SDialog } from "Components/ShareWith7SDialog/ShareWith7SDialog"
import axios, { AxiosError, AxiosResponse } from "axios"

export const ButtonsComponent = ({
  deviceStatus,
  inProgressStates,
  deviceName,
  isDeviceActive,
  bootstrapStateInfo,
}: DeviceDetailProps) => {
  const isQualityAssurance = inProgressStates === EState.ACCEPTANCE
  const axiosAlphasenseFactory = CreateAxiosConfig("/api/v1")
  //We use v1 because that one does not require certificates and we still have no solution for that
  const v1DeviceMonitoring = CreateAxiosConfig("/fleet-monitor/v1")
  const redirect = useRedirect()
  const [isNotFound, setIsNotFound] = useState(false)
  const canProceed = isDeviceActive && ![DeviceStatus.CalibrationFailed].includes(deviceStatus)
  const [openDialogBox, setOpenDialogBox] = useState(false)
  const canSkipFailedSteps = localStorage.getItem("can_skip_failed_steps")
  const [isRepeatClicked, setIsRepeatClicked] = useState(false)
  const [isProceedClicked, setIsProceedClicked] = useState(false)
  const [openDialogShare7S, setOpenDialogShare7S] = useState("")

  const {
    getParsedReport,
    humanReportNameToUrl,
    open,
    setOpen,
    alertOpen,
    setAlertOpen,
    reportDetails,
  } = useReportHook()

  const delimiter = "-"
  const splittedDeviceName = deviceName.split(delimiter)
  const deviceId = splittedDeviceName.pop()
  const deviceType = splittedDeviceName.join(delimiter)
  const isStateFailed = isFailureStatus(deviceStatus)

  const canRepeat = isStateFailed && isDeviceActive
  const sanityCheckFailed = deviceStatus === DeviceStatus.CalibrationSanityCheckFailed

  const setDeviceMonitoringTask = (task: string) => {
    v1DeviceMonitoring.patch(`/robots/${deviceType}/${deviceId}`, { task }).catch(() => {
      setIsNotFound(true)
    })
  }
  const repeatDevice = () => {
    if (sanityCheckFailed) {
      //TODO we should get this to automatically retry instead of just aborting but thats not so easy
      setDeviceMonitoringTask("Failed")
    }
    //There is also the case when calib server crashed, not sure what to do there,
    //for now just assume that task tree will fail quickly after.
    else {
      axiosAlphasenseFactory
        .post(`/devices/${deviceName}/task_tree/start`)
        .then((response: AxiosResponse) => {
          if (response.status === 202) {
            setIsRepeatClicked(true)
          }
        })
        .catch((e: Error | AxiosError) => {
          if (axios.isAxiosError(e)) {
            if (e.response?.status === 401) {
              logOut(redirect)
            } else if (e.response?.status === 404) {
              setIsNotFound(true)
            } else {
              console.error("Failed to start", e)
            }
          } else {
            console.error("Unexpected error:", e)
          }
        })
    }
  }
  const proceedWithDevice = () => {
    if (sanityCheckFailed) {
      setDeviceMonitoringTask("Success")
    } else if (isStateFailed) {
      if (bootstrapStateInfo) {
        //TODO we should have some "confirmation" box in this case
        axiosAlphasenseFactory
          .post(`/devices/${deviceName}/task_tree/skip`, { script: bootstrapStateInfo.node })
          .then((response: AxiosResponse) => {
            if (response.status === 202) {
              setIsProceedClicked(true)
            }
          })
          .catch((e: Error | AxiosError) => {
            if (axios.isAxiosError(e)) {
              if (e.response?.status === 401) {
                logOut(redirect)
              } else if (e.response?.status === 404) {
                setIsNotFound(true)
              } else {
                console.error("Failed to skip", e)
              }
            } else {
              console.error("Unexpected error:", e)
            }
          })
      } else {
        //Some popup saying something along the lines of
        //"We don't know which script is running and therefore can not skip it"
      }
    } else {
      axiosAlphasenseFactory
        .post(`/devices/${deviceName}/task_tree/command`, { command: "proceed" })
        .then((response: AxiosResponse) => {
          if (response.status === 202) {
            setIsProceedClicked(true)
          }
        })
        .catch((e: Error | AxiosError) => {
          if (axios.isAxiosError(e)) {
            if (e.response?.status === 401) {
              logOut(redirect)
            } else if (e.response?.status === 404) {
              setIsNotFound(true)
            } else {
              console.error("Failed to proceed", e)
            }
          } else {
            console.error("Unexpected error:", e)
          }
        })
    }
  }

  const proceedClick = () => {
    if (isStateFailed && bootstrapStateInfo) {
      setOpenDialogBox(true)
    } else {
      proceedWithDevice()
    }
  }

  const buttonLabel = () => {
    if (isStateFailed && bootstrapStateInfo) {
      if (sanityCheckFailed) {
        return AcceptAnyway
      } else {
        return Skip
      }
    } else {
      return Proceed
    }
  }
  const buttonForProceedSkipAccept = () => {
    if (canSkipFailedSteps === "true" || !isStateFailed) {
      return (
        <ProceedButton
          style={{
            background:
              canProceed && !isStateFailed
                ? "green"
                : canProceed
                  ? "#e44411"
                  : "rgba(120, 120, 120, 0.6)",
            cursor: canProceed ? "pointer" : "not-allowed",
          }}
          disabled={!canProceed}
          onClick={proceedClick}
        >
          {isProceedClicked && canProceed ? (
            <CircularProgress style={{ color: "white" }} size="1rem" />
          ) : (
            buttonLabel()
          )}
        </ProceedButton>
      )
    }
  }

  return (
    <DivForButtons>
      {buttonForProceedSkipAccept()}
      <ReportShareButtonsRow>
        <ReportButton
          onClick={() => getParsedReport(deviceName, humanReportNameToUrl(inProgressStates))}
        >
          <Description />
          <span>{ViewReport}</span>
        </ReportButton>
        <ReportButton onClick={() => setOpenDialogShare7S(deviceName)}>
          <span>{ShareWith}</span>
          <img src={logo} width={20} />
        </ReportButton>
      </ReportShareButtonsRow>
      {isQualityAssurance && (
        //Currently always disabled
        <RepeatButton
          disabled={true}
          style={{
            background: "rgba(120, 120, 120, 0.6)",
            cursor: "not-allowed",
          }}
        >
          {RepeatCalibration}
        </RepeatButton>
      )}
      <RepeatButton key={deviceName} onClick={repeatDevice} disabled={!canRepeat}>
        {isRepeatClicked && canRepeat ? (
          <CircularProgress style={{ color: "white" }} size="1rem" />
        ) : sanityCheckFailed ? (
          "ABORT CALIBRATION"
        ) : (
          `REPEAT ${currentState(inProgressStates).toLocaleUpperCase()}`
        )}
      </RepeatButton>
      {isNotFound && (
        <Snackbar
          open={isNotFound}
          autoHideDuration={5000}
          message={`Failed to repeat ${inProgressStates} for ${deviceName}`}
          onClose={() => setIsNotFound(false)}
        />
      )}
      {openDialogBox && (
        <ConfirmationDialogBox
          title={SkipAndProceed}
          message={ConfirmationForSkip}
          buttonText={Skip}
          onConfirm={proceedWithDevice}
          {...{ openDialogBox, setOpenDialogBox }}
        />
      )}
      <Snackbar
        open={alertOpen}
        autoHideDuration={5000}
        message={`Failed to open ${inProgressStates} report`}
        onClose={() => setAlertOpen(false)}
      />
      {open && (
        <ReportPage
          open={open}
          inProgressStates={inProgressStates}
          {...{ setOpen, reportDetails }}
        />
      )}
      {openDialogShare7S && (
        <ShareWith7SDialog deviceName={deviceName} onClose={() => setOpenDialogShare7S("")} />
      )}
    </DivForButtons>
  )
}
