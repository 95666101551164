import { Table, TableBody, TableCell, TableHead, TableRow, tableCellClasses } from "@mui/material"
import {
  HrLine,
  ReportDropDownDiv,
  ReportSelect,
  ShowButton,
  TableMainContainer,
} from "../../styles/FlashStationStyle/InProgressStyle"
import { Date, State, Status, SubStateTitle } from "../../variables/sharedVariables"
import {
  HorizontalLineForSoftwareVersion,
  SoftwareVersion,
  TableCellForDate,
  TableCellForState,
} from "styles/DetailViewStyle"
import { getLocalDate } from "pages/LocalTimeZone"
import { FrontendTransition } from "utility/types"
import { MenuItem, Snackbar } from "@mui/material"
import { useState } from "react"
import { useReportHook } from "utility/useReportHook"
import { ReportPage } from "pages/BootstrappingReports/Reports"

interface DetailViewProps {
  transitions: FrontendTransition[]
  ifBootstrapPage: boolean
  softwareVersion?: string
  showReportDropDown?: boolean
  hostName?: string
}

const parseSoftwareVersion = (softwareVersion: string): string => {
  const splitSoftwareVersion = softwareVersion.split("-")
  const splittedReleaseAndHash = splitSoftwareVersion[1].split("~")
  return `${splittedReleaseAndHash[0]}/${splittedReleaseAndHash[1]} - ${splittedReleaseAndHash[2]}`
}

export const DetailView = ({
  transitions,
  softwareVersion,
  ifBootstrapPage,
  showReportDropDown,
  hostName,
}: DetailViewProps) => {
  const parsedSoftwareVersion = softwareVersion ? parseSoftwareVersion(softwareVersion) : ""
  const options = ["Select report", "Configuration", "Calibration", "Quality Assurance"]
  const [selectedOption, setSelectedOption] = useState(options[0])
  const {
    reportDetails,
    open,
    setOpen,
    getParsedReport,
    humanReportNameToUrl,
    alertOpen,
    setAlertOpen,
  } = useReportHook()

  const currentTransitions = transitions
    .map((item: FrontendTransition, index) => {
      return (
        <TableRow key={`row-${index}`} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCellForDate component="th" scope="row">
            {getLocalDate(item.timestamp)}
          </TableCellForDate>
          <TableCellForState align="left">{item.state}</TableCellForState>
          <TableCell align="left">{item.substate}</TableCell>
          <TableCell align="left" style={{ width: "150px" }}>
            {item.status}
          </TableCell>
        </TableRow>
      )
    })
    .reverse()

  return (
    <TableMainContainer>
      {ifBootstrapPage && showReportDropDown ? (
        <>
          <ReportDropDownDiv>
            <ReportSelect
              value={selectedOption}
              onChange={(event: any) => setSelectedOption(event.target.value as string)}
            >
              {options.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </ReportSelect>
            <ShowButton
              onClick={() =>
                hostName && getParsedReport(hostName, humanReportNameToUrl(selectedOption))
              }
              style={{ marginRight: "40px" }}
            >
              OPEN
            </ShowButton>
          </ReportDropDownDiv>
          <HrLine />
        </>
      ) : (
        <></>
      )}
      <Table
        sx={{
          width: "100%",
          minWidth: 650,
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>{Date}</TableCell>
            <TableCell align="left">{State}</TableCell>
            <TableCell align="left">{SubStateTitle}</TableCell>
            <TableCell align="left">{Status}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{currentTransitions}</TableBody>
      </Table>
      {ifBootstrapPage && (
        <>
          <HorizontalLineForSoftwareVersion />
          <SoftwareVersion>Software version: {parsedSoftwareVersion} </SoftwareVersion>
        </>
      )}
      <Snackbar
        open={alertOpen}
        autoHideDuration={5000}
        message={`Failed to open ${selectedOption.toLocaleLowerCase()} report`}
        onClose={() => setAlertOpen(false)}
      />
      {open && reportDetails && (
        <ReportPage open={open} inProgressStates={selectedOption} {...{ setOpen, reportDetails }} />
      )}
    </TableMainContainer>
  )
}
