import styled, { css } from "styled-components"
import ImageIcon from "@mui/icons-material/Image"

export const DivWithPadding = styled.div`
  padding-top: 10px;
`
export const CenteredTitle = styled.h2`
  text-align: center;
  padding: 0 40px 0px 40px;
`
export const CommonFlex = css`
  display: flex;
  justify-content: space-evenly;
`
export const FlexDirectionRow = styled.div`
  ${CommonFlex}
`
export const FlexDirectionColumn = styled.div`
  ${CommonFlex}
  flex-direction: column;
`
export const Flex = css`
  display: flex;
`
export const ColumnWithoutJustified = styled.div`
  ${Flex}
  flex-direction: column;
`
export const StartStopButton = styled.button`
  height: 35px;
  background: #2f4b8a;
  width: 100px;
  border-radius: 5px;
  border-color: transparent;
  color: white;
  opacity: 78%;
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  &.disabled {
    background: rgba(120, 120, 120, 0.6);
    cursor: not-allowed;
  }
`
export const BlackSpan = styled.span`
  color: black;
`
export const BlueSpan = styled.span`
  color: blue;
  cursor: pointer;
`
export const CenterP = styled.p`
  text-align: center;
`
export const JustifiedFlex = styled.div`
  ${Flex}
  flex-wrap: wrap;
  padding-top: 20px;
  padding-right: 10%;
`
export const JustifiedColumn = styled(ColumnWithoutJustified)`
  padding-right: 20px;
`
export const PlaceholderImage = styled(ImageIcon)`
  width: 80px;
  height: 40px;
  color: gray;
`
export const SuccessTitle = styled.h4`
  margin-top: 50px;
  padding-left: 100px;
`
export const FlexCenter = styled.div`
  ${Flex}
  justify-content: center;
  align-items: center;
`
export const CenteredDiv = styled(ColumnWithoutJustified)`
  width: 100%;
  justify-content: center;
  align-items: center;
`
export const BottomPaddedSpan = styled.span`
  padding-bottom: 10px;
`
export const Div = styled.div`
  ${Flex}
  justify-content: space-between;
  width: 60%;
`
export const FrontendImage = styled.img`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
`
export const ImageBackground = styled.div`
  display: flex;
  flex-direction: column;
  background: black;
`
export const ReportTrueValue = styled.p`
  color: green;
`
export const ReportFalseValue = styled.p`
  color: red;
`
