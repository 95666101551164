import { CreateAxiosConfig } from "pages/AxiosConfig"
import { useState } from "react"
import { logOut } from "./user_authentication"
import { ReportProps } from "pages/CalibrationReport/CalibrationReport"
import { useRedirect } from "react-admin"
import axios, { AxiosError, AxiosResponse } from "axios"

const initialValues = {
  device_id: "",
  device_type: "",
  entities: {},
  timestamp_utc: "",
  success: false,
  report_type: "",
}
export const useReportHook = () => {
  const axiosFactoryReportParser = CreateAxiosConfig("/factory-report-parser")
  const [open, setOpen] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [reportDetails, setReportDetails] = useState<ReportProps>(initialValues)
  const redirect = useRedirect()

  const humanReportNameToUrl = (humanReportName: string): string => {
    switch (humanReportName) {
      case "Configuration":
        return "configuration"
      case "Calibration":
        return "calibration"
      case "Quality Assurance":
        return "quality_assurance"
      default:
        throw Error(`Unknown report type: ${humanReportName}`)
    }
  }
  const getParsedReport = (deviceName: string, state: string) => {
    const delimiter = "-"
    const splittedDeviceName = deviceName.split(delimiter)
    const robotId = splittedDeviceName.pop()
    const robotType = splittedDeviceName.join(delimiter)
    axiosFactoryReportParser
      .get(`/${state}/${robotType}/${robotId}`)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          setOpen(true)
          setReportDetails(response.data)
        } else {
          setAlertOpen(true)
        }
      })
      .catch((e: Error | AxiosError) => {
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 401 || e.response?.status === 403) {
            logOut(redirect)
          } else if (e.response?.status === 404) {
            setAlertOpen(true)
          } else {
            console.error("Failed to get report data", e)
          }
        } else {
          console.error("Unexpected error:", e)
        }
      })
  }
  return {
    open,
    setOpen,
    alertOpen,
    setAlertOpen,
    reportDetails,
    getParsedReport,
    humanReportNameToUrl,
  }
}
